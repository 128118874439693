<div class="fade-in-content">
      <p class="article-header">
      Ресурсы
      </p>
      <p class="article-text">
        Переход на ресурсный метод определения сметной стоимости и выход новых нормативно-правовых документов (Методика № 421/пр от 4 августа 2020) предъявляет особые требования к сметной документации. 
        Сметная документация выполняется  специалистами Центра ценообразования в соответствии с действующими нормативно-законодательными документами в области ценообразования.
      </p>
<div class="p3">
<ul>
<li>Подготовка расчета среднемесячного размера оплаты труда рабочего 1-го разряда, занятого в строительной отрасли.</li>
<li>Выполнение расчета стоимости 1 маш.ч. по всей номенклатуре машин и механизмов, предусмотренной классификатором строительных ресурсов</li>
<li>Выполнение расчета тарифов на доставку строительных материалов франко-приобъектный склад, с учетом классификации грузов, а также видов применяемых перевозок и транспортных средств, в том числе перевозки авиатранспортом (вертолеты), водные перевозки (морские, речные), железнодорожные перевозки (внутрироссийские, ведомственные), автомобильные перевозки с учетом класса дорог.</li>
<li>Проведение конъюнктурного анализа стоимости материальных ресурсов по 3-м поставщикам с выдачей подтверждающих документов и определение транспортных затрат по доставке строительных грузов в указанный Заказчиком регион (город, поселок) с выдачей подтверждающих расчетов.</li>
<li>Формирование транспортных схем по доставке привозных и местных материалов до объектов строительства, расположенных в населенных пунктах субъектов Федерации, с разбивкой по ценовым зонам, учитывающим природно-климатические факторы, разработка (расчет) транспортных и зональных коэффициентов.</li>
</ul>
</div>
<div class="p4">
Предлагаем <span class="link-inline" routerLink="/services/smets/indexes">приобрести</span> печатные и электронные (в формате ПК "ГРАНД-Смета") сборники текущих сметных цен на: 
</div>
<div class="p4 ml-1">
<ul>
<li>строительные материалы;</li>
<li>тарифные ставки рабочих и служащих;</li>
<li>стоимость 1 маш.ч. механизмов, используемых при строительстве;</li>
<li>тарифы на погрузо-разгрузочные работы и перевозку грузов.</li>
</ul>
</div>
<p class="p4 ml-4">
А также индексы пересчета ТСНБ-2001 Омской области в текущий уровень цен.
</p>
