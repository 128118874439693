<div class="fade-in-content">
      <p class="article-header pt-3 text-left">Строительная слава</p>
      <p class="article-text">
        Центр ценообразования обладает высшей общественной наградой работников строительного комплекса РФ, учрежденной Российским Союзом Строителей - почетным знаком «Строительная слава».
      </p>

      <div class="row w-100 mx-0">
        <div class="col px-0">
          <img class="" style="float:right; margin: 0px 0px 20px 20px; height:200px" src="../../images/awards/EuroQuality.png" />
            <p class="article-header pt-3 text-left">Европейское качество</p>
            <p class="article-text">
Имеем Международную награду "EUROPEAN QUALITY" ("Европейское качество"), врученную в 2010 году на международном форуме, за высокое качество услуг в сфере ценообразования в строительстве, промышленности и энергетике.
            </p>
        </div>
      </div>

      <div class="row w-100 mx-0">
        <div class="col px-0">
          <p class="article-header pt-3 text-left">Аккредитация ФГБУ "ЦНИИП Минстроя России"</p>
            <a class="image-link" title="Свидетельство НОССИ" target="_blank" href="../../images/awards/cniip_akkred.jpg">
              <img class="" style="float:left; margin: 0px 20px 20px 0px; height:200px" src="../../images/awards/cniip_akkred.jpg" />
            </a>
          <p class="article-text">
Выдана Центру ценообразования в качестве аккредитованной организации при Минстрое России по виду деятельности. 
          </p>
        </div>
      </div>

      <p class="article-header pt-3 text-left">Золотая медаль SPI</p>
      <p class="article-text">
Генеральный директор награждена знаком «Золотая медаль SPI» и именным дипломом «Ассоциации содействия промышленности» (SPIX Франция) за личный вклад в область стратегического менеджмента.      
      </p>
      <p class="article-header pt-3 text-left">Свидетельство СРО НП «Национальное объединение специалистов стоимостного инжиниринга»</p>
      <div class="row mx-0">
        <div class="col-sm-6 text-center px-0">
          <a class="image-link " title="Свидетельство НОССИ" target="_blank" href="../../files/nossi.pdf">
            <img class="" style="max-height:300px; margin: 0px 0px 0px 0px" src="../../images/awards/nossi1.jpg" />
          </a>
        </div>
        <div class="col-sm-6 text-center px-0">
          <a class="image-link" title="Свидетельство НОССИ" target="_blank" href="../../files/nossi.pdf">
            <img class="" style="max-height:300px; margin: 0px 0px 0px 0px" src="../../images/awards/nossi2.jpg" />
          </a>
        </div>
      </div>
      <p class="article-header pt-4 text-left">Авторские свидетельства</p>
      <div class="row mx-0">
        <div class="col-sm-6 col-md-3 text-center">
          <a class="image-link" title="ТСНБ-2001 Омской области" target="_blank" href="../../images/awards/Reg_SNB-2001.jpg">
            <img class="w-100" style="margin: 0px 0px 0px 0px" src="../../images/awards/Reg_SNB-2001.jpg" />
            <div class="link-inline">ТСНБ-2001 Омской области</div>
          </a>
        </div>
        <div class="col-sm-6 col-md-3 text-center">
          <a class="image-link" title="ТЭСНро-2007 Омской обл" target="_blank" href="../../images/awards/Reg_ESNro-2007.jpg">
            <img class="w-100" style="margin: 0px 0px 0px 0px" src="../../images/awards/Reg_ESNro-2007.jpg" />
            <div class="link-inline">ТЭСНро-2007 Омской обл</div>
          </a>
        </div>
        <div class="col-sm-6 col-md-3 text-center">
          <a class="image-link" title="Ежеквартальные индексы к ТСНБ" target="_blank" href="../../images/awards/Reg_index.jpg">
            <img class="w-100" style="margin: 0px 0px 0px 0px" src="../../images/awards/Reg_index.jpg" />
            <div class="link-inline">Ежеквартальные индексы к ТСНБ</div>
          </a>
        </div>
        <div class="col-sm-6 col-md-3 text-center">
          <a class="image-link" title="СНБ ЖКХ Омской области" target="_blank" href="../../images/awards/Reg_JKH-2007.jpg">
            <img class="w-100" style="margin: 0px 0px 0px 0px" src="../../images/awards/Reg_JKH-2007.jpg" />
            <div class="link-inline">СНБ ЖКХ Омской области</div>
          </a>
        </div>
      </div>

      <p class="article-header pt-3 text-left">Судебная строительно-техническая-экспертиза</p>
      <div class="row w-100 mx-0">
        <div class="col px-0 text-center">
          <a class="image-link" title="Диплом эксперта" target="_blank" href="../../images/awards/stroit_expert.jpg">
            <img class="" style="margin: 0px 0px 20px 20px; height:300px" src="../../images/awards/stroit_expert.jpg" />
          </a>

        </div>
      </div>

      <p class="article-header pt-3 text-left">Свидетельство о государственной регистрации базы данных</p>
      <div class="row w-100 mx-0">
        <div class="col px-0 text-center">
          <a class="image-link" title="Свидетельство" target="_blank" href="../../files/Свидетельство_регистрация ЭСНро и ТОиР.pdf">
            <img class="" style="margin: 0px 0px 20px 20px; height:300px" src="../../images/awards/registr_toir.png" />
          </a>
        </div>
      </div>


</div>