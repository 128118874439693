import { Component, AfterViewChecked } from "@angular/core";
import { PriceProgsRepository } from "../../model/PriceProgs.repository";
import { PriceProgsItem } from "../../model/PriceProgsItem.model";
import { AppComponent } from '../../app.component';

declare var $:any;

@Component({
    selector: "smets/progs",
    templateUrl: "progs.component.html"
})
export class ServicesSmetsProgsComponent {
    private loadedPrice: PriceProgsItem[]=[];
    private ttenabled: boolean = false;

    constructor (private appl: AppComponent, private repository: PriceProgsRepository) {}

    ngAfterViewChecked() {
         if (this.appl.isBrowser && !this.ttenabled && this.loadedPrice.length!=0) {
           this.ttenabled = true;
           $('.tt').tooltip();
         }
    }

   get price(): PriceProgsItem[] {          
       if (this.loadedPrice.length==0) this.loadedPrice = this.repository.getPrice();
       return this.loadedPrice;
   }

}
