import { Injectable } from "@angular/core";
import { PriceProgsItem } from "./PriceProgsItem.model";
import { srccsWebServiceDataSource } from "./srccsWebService.datasource";

@Injectable({providedIn: 'root'})
export class PriceProgsRepository {
    private price: PriceProgsItem[] = [];

    constructor(private api: srccsWebServiceDataSource) {
        api.getData("price", "progs").subscribe(data => {
            this.price = srccsWebServiceDataSource.parseTooltip(data);
        });
    }

    getPrice(): PriceProgsItem[] {
        return this.price;
    }

}
