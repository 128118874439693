<div class="fade-in-content">
      <p class="article-header">
      Курсы повышения квалификации специалистов сметных отделов, инженеров стоимостного инжиниринга, экономистов планово-экономических отделов
      </p>
      <p class="article-text">
В целях эффективной работы, повышения уровня теоретических знаний нормативов и расценок, совершенствования практических навыков определения сметной стоимости с учетом изменений законодательства в области сметного нормирования и ценообразования организованы курсы повышения квалификации специалистов по программе Ценообразование и сметное нормирование.</p>
      <p class="p3">
В рамках учебного курса наши преподаватели подготовили для Вас лекции по следующим дисциплинам:
      </p>
      <div class="p4">
        <ul>
<li>нормативные и методические документы в актуальной редакции;</li>
<li>сметно-нормативная база ФСНБ-2020 с учетом последних изменений;</li>
<li>сметно-нормативная база ФСНБ-2022 с учетом последних изменений и дополнений;</li>
<li>федеральный сборник сметных цен (ФССЦ-2001);</li>
<li>федеральный сборник базовых цен (ФСБЦ-2022);</li>
<li>методы определения сметной стоимости: ресурсный, ресурсно-индексный, базисно-индексный;</li>
<li>особенности определения сметной стоимости в связи с переходом на  ресурсно-индексным методом;</li>
<li>методика определения НМЦК и составления сметы контракта;</li>
<li>нормативные показатели расхода ресурсов;</li>
<li>сметное нормирование оплаты труда рабочих;</li>
<li>тарифная система;</li>
<li>принципы формирования оплаты труда;</li>
<li>детализация структуры затрат эксплуатации машин и механизмов;</li>
<li>расчет стоимости 1 маш/часа;</li>
<li>транспортные схемы перевозки грузов;</li>
<li>классификация строительных ресурсов;</li>
<li>правила ведения мониторинга сметных цен строительных ресурсов;</li>
<li>накладные расходы и сметная прибыль;</li>
<li>локальные сметы;</li>
<li>объектная смета;</li>
<li>сводный сметный расчет;</li>
<li>начисление дополнительных затрат при производстве СМР;</li>
<li>проектные и изыскательские работы;</li>
<li>укрупненные нормативы цены строительства;</li>
<li>применение индексов изменения сметной стоимости;</li>
<li>мастер-класс демонстрации возможностей использованием ПК "Гранд-Смета";</li>
<li>составление локальных смет на все виды работ с использованием ПК "Гранд-Смета".</li>
        </ul>
      </div>
      <p class="p3">
Обучение проходит в группах по 10-15 человек. Продолжительность обучения 2 недели (72 часа).
      </p>
      <p class="p3">
Преподавательский состав – главные специалисты и руководители ЗАО "Сибирский ЦЦСПЭ".
      </p>
      <p class="p3">
Предоставляем – методические материалы по всем темам.
      </p>
      <p class="p3">
Документы об окончании - Удостоверение о краткосрочном повышении квалификации.
      </p>
      <p class="p3">
Курсы проходят в специально оборудованном классе по адресу: г. Омск, ул. Октябрьская, д.33/1.
      </p>
      <p class="p3">
Контактные данные: (3812) 37-14-50, +7 (913) 651-70-46.
      </p>
      <p class="h4 text-center pt-3">
Стоимость обучения <strong>20 000 руб.</strong> на одного слушателя.
      </p>
      <p class="article-text pt-4">
Образовательная деятельность осуществляется на основании лицензии Министерства образования Омской области от 03 октября 2016г. № 85п.
      </p>
</div>
