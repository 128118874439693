import { Injectable } from "@angular/core";
import { PriceMarketAnalysisItem } from "./PriceMarketAnalysisItem.model";
import { srccsWebServiceDataSource } from "./srccsWebService.datasource";

@Injectable({providedIn: 'root'})
export class PriceMarketAnalysisRepository {
    private price: PriceMarketAnalysisItem[] = [];

    constructor(private api: srccsWebServiceDataSource) {
        api.getData("price", "marketanalysis").subscribe(data => {
            this.price = srccsWebServiceDataSource.parseTooltip(data);
        });
    }

    getPrice(): PriceMarketAnalysisItem[] {
        return this.price;
    }

}
