<div class="main-content fade-in-content new-year-margin"> 
  <div class="container-fluid">
    <div class="row justify-content-center align-items-center section-header new-year-padding">
      <h1>Новости</h1>
    </div>
    <div class="row justify-content-center align-items-center"> <!--  style="position: sticky; top: 200px;z-index:1; background:#FAFCF4" -->
      <div class="col px-1 py-2 keep-width-over900 text-center">

        <!--<object *ngIf="currentFilter==0" class="icon-inline" type="image/svg+xml" data="../images/checkbox-checked.svg"></object>
        <object *ngIf="currentFilter!=0" class="icon-inline" type="image/svg+xml" data="../images/checkbox-unchecked.svg"></object>-->

        <button *ngIf="existNews(0)" class="btn btn-primary" (click)="currentFilter=0" [class.filter-active]="currentFilter==0">Актуальные</button>
        <button *ngIf="existNews(currentYear)" class="btn btn-primary" (click)="currentFilter=currentYear" [class.filter-active]="currentFilter==currentYear">{{currentYear}}</button>
        <button *ngIf="existNews(currentYear-1)" class="btn btn-primary" (click)="currentFilter=currentYear-1" [class.filter-active]="currentFilter==currentYear-1">{{currentYear-1}}</button>
        <button *ngIf="existNews(1)" class="btn btn-primary" (click)="currentFilter=1" [class.filter-active]="currentFilter==1">Архив</button>
      </div>
    </div>
    <div *ngFor="let item of allNews; let i = index;" id="news_{{i}}" class="row py-4 justify-content-center fade-in-content">
      <div class="col keep-width-over900">
        <div class="holder-paddings bg-white shadow-sm article-holder">
          <div actual class="news-date">{{item.published.toLocaleDateString()}}</div>
          <table class="w-100" [class.news-header-image-left]="item.imageLocation == locationSide.Left"
             [class.news-header-image-right]="item.imageLocation == locationSide.Right"
             [class.news-header-image-none]="item.imageLocation == locationSide.None">
            <tr>
              <td style="">
                <div *ngIf="item.imageLocation != locationSide.Left" [innerHTML]="item.head"></div>
                <img *ngIf="item.imageLocation == locationSide.Left" src="../images/news/{{item.image}}" />
              </td>
              <td>
                <div *ngIf="item.imageLocation == locationSide.Left" [innerHTML]="item.head"></div>
                <img *ngIf="item.imageLocation == locationSide.Right" src="../images/news/{{item.image}}" />
              </td>
            </tr>
          </table>
          <div class="news-text" [innerHTML]="item.body | safeHtml" (click)="getRoute($event)"></div>
        </div>        
      </div>  
    </div>
   </div>
</div>
