import { Component, Injectable, HostListener, AfterViewInit, ViewChild, ElementRef, OnInit, OnDestroy, Inject, PLATFORM_ID} from '@angular/core';
import { Router, RoutesRecognized, ActivatedRoute, NavigationEnd } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import ResizeObserver  from 'resize-observer-polyfill';
import { SEOService } from "./model/seoservice";
import { filter, map, mergeMap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

declare var $:any;
declare function offsetTopRoot(id: string): number;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

@Injectable()
export class AppComponent {
  public currPage: number;
  public visibleMenuNav: boolean = false;
  public pageScrolled: boolean = false;
  private _isWidthTablet: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isCaptive: boolean = false;
  public lastErrorNotFound: boolean = false;
  public isBrowser = false;
 
  constructor(private router: Router, private activeRoute: ActivatedRoute, private elementRef: ElementRef,
              private seoService: SEOService, @Inject(PLATFORM_ID) private platformId: Object) {
    //this.teststr = activeRoute.snapshot.url[0];
    
    this.isBrowser = isPlatformBrowser(this.platformId);

    router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        this.isCaptive = event.url.indexOf ('/guest/s/default?ap=') == 0;

        if (this.lastErrorNotFound) {
          this.lastErrorNotFound = false;
//          console.log("error page detected");
          if (this.isBrowser) 
          window.open(event.url,'_self');
        }
      }
    });

    this.router.events.pipe(
       filter((event) => event instanceof NavigationEnd),
       map(() => this.activeRoute),
       map((route) => {
         while (route.firstChild) route = route.firstChild;
         return route;
       }),
       filter((route) => route.outlet === 'primary'),
       mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        //console.log (event['title']);
        this.seoService.updateTitle(event['title']);
        this.seoService.updateDescription(event['description']);
        this.seoService.updateCanonicalURL();
      }); 

  }

//  public screenSize: string;
  public directHeaderChange: number = 0;
  @ViewChild('headbgimg') headbgimg: ElementRef; 
  public smallHeader: boolean = false;
  public headdivchange: any;

  ngOnInit() {
    //this.currPage=1;
//    this.screenSize = window.innerWidth + ","+window.innerHeight;//+"-"+window.outerWidth + ","+window.outerHeight;
    // console.log(this.screenSize);
    // mobile: 360x512, 592x280
    // tablet: 800x1112, 1280x632
    this.checkWidth();

    this.headdivchange = new ResizeObserver(entries => {
        const cr = entries[0].contentRect;
//              console.log(cr.height);
        if (this.directHeaderChange == 0) {
          this.directHeaderChange = cr.height;
        } else {
          if (this.directHeaderChange < cr.height) 
          {
            if (cr.height > 190) {
              this.headdivchange.unobserve(this.headbgimg.nativeElement);
              if (this.smallHeader) this.smallHeader = false;
//              console.log('false');
            }
          } else if (this.directHeaderChange > cr.height) {
            if (cr.height < 190) { 
              this.headdivchange.unobserve(this.headbgimg.nativeElement);
              if (!this.smallHeader) this.smallHeader = true; 
//              console.log('true');
            }
          }
        }
        //console.log (this.directHeaderChange + " : " + cr.height);
    });
  }

  toggleMenuNav(showhide: boolean, e) {
    this.visibleMenuNav = showhide;
    if (showhide) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  public isWidthTablet(): Observable<boolean> {
    return this._isWidthTablet.asObservable();
  }

  checkWidth() {
    let newWidth = false;
    if (this.isBrowser) {
      newWidth = window.innerWidth < 1000; 
    }      
    if (newWidth != this._isWidthTablet.value) { this._isWidthTablet.next(newWidth); }
  }

  @HostListener('window:load', ['$event']) 
  onload(event) {
    if(this.visibleMenuNav) { this.toggleMenuNav(false,null); }
    this.checkWidth();
    if (window.innerHeight < 600) {
          if (!this.pageScrolled) { this.pageScrolled = true; }
    } else {
          if (this.pageScrolled) { this.pageScrolled = false; }
    }
    //this.sizeobserver.observe(this.headdiv.nativeElement);  
  }

  @HostListener('window:resize', ['$event']) 
  onresize(event) {
    if(this.visibleMenuNav) this.toggleMenuNav(false,null);
//    this.screenSize = window.innerWidth + ","+window.innerHeight;//+"-"+window.outerWidth + ","+window.outerHeight;
    this.checkWidth();
    if (window.innerHeight < 600) {
          if (!this.pageScrolled) { this.pageScrolled = true; }
    } else {
          if (this.pageScrolled) { this.pageScrolled = false; }
    }
    //console.log(window.innerHeight + " - " + this.pageScrolled);
  }

  @HostListener('window:scroll', ['$event']) 
  onscroll(event) {
    if(this.visibleMenuNav) this.toggleMenuNav(false,null);
    let hih = event.srcElement.getElementById('headbgimg').height
    //console.log (event.srcElement.documentElement.scrollTop +", hih=" + hih);
    if (!this.pageScrolled && event.srcElement.documentElement.scrollTop > 150) {
      //console.log ("pageScrolled");
      this.pageScrolled = true;
    }
    if (this.pageScrolled && event.srcElement.documentElement.scrollTop < 50) {
      if (window.innerHeight > 600) { this.pageScrolled = false; }
    }
  } 

  anchorShift: boolean = false;
  transitionEnd(e: Event){
    this.directHeaderChange = 0;
    //console.log("end " +   this.headbgimg.nativeElement.height);
    //console.log(this.anchorShift);
    this.headdivchange.unobserve(this.headbgimg.nativeElement);  
    if (this.headbgimg.nativeElement.height > 155) { this.smallHeader = false; } else { this.smallHeader = true; }
    if (this.anchorShift && this.activeRoute.snapshot.fragment) {
        this.anchorShift = false;
        $('html,body').animate({ scrollTop: offsetTopRoot(this.activeRoute.snapshot.fragment)-(this.smallHeader? -100 : 0) });          
   }
 }

  transitionStart(e: Event){
    this.directHeaderChange = 0;
    //console.log("estart " +   this.headbgimg.nativeElement.height);
    this.headdivchange.observe(this.headbgimg.nativeElement);  
    //if (!this.smallHeader && this.headbgimg.nativeElement.height > 155) { this.smallHeader = true; }
  }                       

}