import { Component } from "@angular/core";
//import { SectionsComponent } from '../sections.component';
//import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "portfolio",
    templateUrl: "portfolio.component.html"
})
export class AboutPortfolioComponent {
    //public currroute: String;
     
    //constructor (private activeRoute: ActivatedRoute, private sect: SectionsComponent) {
    //  this.currroute = activeRoute.snapshot.url[0]?.path;
    //}
}
