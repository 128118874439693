import { Injectable, Inject, Optional, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
import { Observable } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { Request } from 'express';
import { REQUEST } from '@nguniversal/express-engine/tokens';

@Injectable({providedIn: 'root'})
export class srccsWebServiceDataSource {
    baseUrl: string;

    constructor(private httpClient: HttpClient, @Inject(PLATFORM_ID) private platformId: Object, @Optional() @Inject(REQUEST) protected request: Request) {
        if (isPlatformBrowser(this.platformId)) {
          this.baseUrl = location.protocol + `//srccs.ru/api/`;
        } else {
          this.baseUrl = this.request.protocol + `://srccs.ru/api/`;
        }
        //this.baseUrl = `http://192.168.30.54:5000/`;
        //console.log (this.baseUrl);
    }

    public requestPhone(macaddress :string, apmac :string, phonenumber: string): Observable<any>{
      return this.httpClient.jsonp(this.baseUrl + "CaptiveCallbackPhone/" + phonenumber + "/" + macaddress + "/" + apmac,'callback');        
    }

    public checkCaptiveLogon(macaddress :string): Observable<any>{
      return this.httpClient.jsonp(this.baseUrl + "CaptiveCheckConnect/" + macaddress,'callback');        
    }

    public getData(name: string, subName?: string, subSubName?: string): Observable<any>{
      let url = this.baseUrl + "site/" + name;
      if (subName != null) {
        url=url + "/" + subName;
        if (subSubName != null)
          url=url + "/" + subSubName;
      }   

      //console.log ('get data from: ' + url);
      
      if (!isPlatformBrowser(this.platformId)) return this.httpClient.get(url);
        //.subscribe(response => {
        //console.log (response);
        //});
      return this.httpClient.jsonp(url,'callback');
    }

    static parseTooltip (loadedPrice: any) : any {
         for (let i = 0; i < loadedPrice?.length; i++) {
           for (let j = 0; j < loadedPrice[i].tt?.length; j++) {
             let ttindex = loadedPrice[i].name.indexOf ('<tt>');
             if (ttindex !=-1) {
               loadedPrice[i].name = loadedPrice[i].name.slice (0,ttindex) + '<span class="tt" data-toggle="tooltip" data-trigger="hover click" data-placement="bottom" data-html="true" title="' + 
                   loadedPrice[i].tt[j].replace(/\"/g,'&quot;') + '">' + 
                   loadedPrice[i].name.slice (ttindex+4,loadedPrice[i].name.length);

               loadedPrice[i].name = loadedPrice[i].name.replace('</tt>','</span>');
             } else { break; }

           }
           loadedPrice[i].name = loadedPrice[i].name.replace(/<tt>/g,'').replace(/<\/tt>/g,'');
         }
     return loadedPrice;
   }
}
