import { Component } from "@angular/core";

@Component({
    selector: "detailed",
    templateUrl: "detailed.component.html"
})


export class ContactsDetailedComponent {
}
