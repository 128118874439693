import { Component, AfterViewChecked } from "@angular/core";
import { PriceMarketAnalysisRepository } from "../../model/PriceMarketAnalysis.repository";
import { PriceMarketAnalysisItem } from "../../model/PriceMarketAnalysisItem.model";
import { AppComponent } from '../../app.component';

declare var $:any;

@Component({
    selector: "resources/monitoring",
    templateUrl: "monitoring.component.html"
})
export class ServicesResourcesMonitoringComponent {
    private loadedPrice: PriceMarketAnalysisItem[]=[];
    private ttenabled: boolean = false;

    constructor (private appl: AppComponent, private repository: PriceMarketAnalysisRepository) {}

    ngAfterViewChecked() {
         if (this.appl.isBrowser && !this.ttenabled && this.loadedPrice.length!=0) {
           this.ttenabled = true;
           $('.tt').tooltip();
         }
    }

   get price(): PriceMarketAnalysisItem[] {          
       if (this.loadedPrice.length==0) this.loadedPrice = this.repository.getPrice();
       return this.loadedPrice;
   }
}
