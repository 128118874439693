import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ContactsPrimaryComponent } from "./contacts/primary.component";
import { ChangeDetectorRef } from '@angular/core';

import { NewsRepository } from "./model/News.repository";
import { NewsItem } from "./model/NewsItem.model";
import { LocationSide } from "./model/LocationSide.model";

@Component({
    selector: "news",
    templateUrl: "news.component.html",
    styleUrls: ['./news.component.css']
})
export class NewsComponent {
  public teststr: String;
  locationSide = LocationSide;
  currentFilter: number = 0;
  existNewsFlags: number[] = [-1,-1,-1,-1];

  constructor(private router: Router, private activeRoute: ActivatedRoute, private repository: NewsRepository, private cdRef:ChangeDetectorRef){
    //this.teststr = activeRoute.snapshot.url[0].path;
  }

  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

   getRoute(event) {
                var routePath = event.target.getAttribute('routerLink');
                var routeFragment = event.target.getAttribute('fragment');
                if (routePath) {
                  if (routeFragment)
                    this.router.navigate([routePath], {fragment: routeFragment});
                  else
                    this.router.navigate([routePath]);
                }
   }
   
   get currentYear(): number {
     let now=new Date();
     return now.getFullYear();
   }

   getNews(filter: number): NewsItem[] {
       let fullNewsList = this.repository.getNews();
       let now=new Date();
       let visibleNewsList = fullNewsList.filter (el=>el.expired>=now);
       let visibleNewsList1: NewsItem[];
       if (filter != 0) {
         if (filter == 1) {
           visibleNewsList1 = fullNewsList.filter (el=>el.published.getFullYear()<this.currentYear-1);
         } else {
           visibleNewsList1 = fullNewsList.filter (el=>el.published.getFullYear()==filter);
         }
         visibleNewsList = visibleNewsList1.filter (el=>!visibleNewsList.includes(el));
       }
       return visibleNewsList;
   }

   get allNews(): NewsItem[] {       
       return this.getNews(this.currentFilter);
   }

   public existNews(filter: number): boolean {
     let len = this.getNews(filter).length; 
     switch (filter) {
       case 0:
         this.existNewsFlags[0] = len;
         if (len == 0 && this.currentFilter == 0) {
           if (this.existNewsFlags[1] > 0)
             this.currentFilter = this.currentYear;
           else if (this.existNewsFlags[2] > 0)
             this.currentFilter = this.currentYear-1;
           else if (this.existNewsFlags[3] > 0)
             this.currentFilter = 1;
         }
         break;
       case this.currentYear:
         this.existNewsFlags[1] = len;
         if (len == 0 && this.currentFilter == this.currentYear) {
           if (this.existNewsFlags[0] > 0)
             this.currentFilter = 0;
           else if (this.existNewsFlags[2] > 0)
             this.currentFilter = this.currentYear-1;
           else if (this.existNewsFlags[3] > 0)
             this.currentFilter = 1;
         }
         break;
       case this.currentYear-1:
         this.existNewsFlags[2] = len;
         if (len == 0 && this.currentFilter == this.currentYear-1) {
           if (this.existNewsFlags[0] > 0)
             this.currentFilter = 0;
           else if (this.existNewsFlags[1] > 0)
             this.currentFilter = this.currentYear;
           else if (this.existNewsFlags[3] > 0)
             this.currentFilter = 1;
         }
         break;
       case 1:
         this.existNewsFlags[3] = len;
         if (len == 0 && this.currentFilter == 1) {
           if (this.existNewsFlags[0] > 0)
             this.currentFilter = 0;
           else if (this.existNewsFlags[1] > 0)
             this.currentFilter = this.currentYear;
           else if (this.existNewsFlags[2] > 0)
             this.currentFilter = this.currentYear-1;
         }
         break;
     }
     return len>0;
   }

}
