import { Component, OnInit, Inject, Optional } from "@angular/core";
import { AppComponent } from './app.component';
import { Router } from '@angular/router';
import {RESPONSE} from '@nguniversal/express-engine/tokens';



@Component({
    selector: "notfound",
    templateUrl: "notfound.component.html"
})
export class NotFoundComponent { 
  constructor(private appl: AppComponent, public router: Router, @Optional() @Inject(RESPONSE) private response) {}
 
  ngOnInit() {
    this.appl.lastErrorNotFound = true;
    if (!this.appl.isBrowser) 
      this.response.status(404); 
  }
}
