import { Router, ActivatedRoute } from "@angular/router";
import { Component, ElementRef, ViewChild } from "@angular/core";
import { Observable } from "rxjs";
import { AppComponent } from '../app.component';

declare var $:any;
declare function offsetTopRoot(id: string): number;

@Component({
    selector: "primary",
    templateUrl: "primary.component.html"
})


export class ContactsPrimaryComponent {

   @ViewChild('mapsrccs') mapsrccs: ElementRef; 
   @ViewChild('headbgimg') headbgimg: ElementRef; 

   public teststr: String;

   constructor(private router: Router, private activeRoute: ActivatedRoute, private appl: AppComponent ){
   }

   ngAfterViewInit() {
     //console.log(this.activeRoute.snapshot.fragment + ": " + offsetTopRoot(this.activeRoute.snapshot.fragment));
     //console.log(this.appl.smallHeader);
     if (this.appl.isBrowser && this.activeRoute.snapshot.fragment) {
       this.appl.anchorShift = true;
       $('html,body').animate({ scrollTop: offsetTopRoot(this.activeRoute.snapshot.fragment)-(this.appl.smallHeader? -100 : 0) });          
     }
   }

   goToMap() {
     $('html,body').animate({
       scrollTop: offsetTopRoot(this.mapsrccs.nativeElement.id)-(this.appl.smallHeader? 100 : 200)
     });          
   }
}
