<div class="fade-in-content">
      <p class="article-header">
      Строительство
      </p>
      <p class="p3">
Корпоративные базы и ресурсные нормы это:
      </p>
      <div class="p4">
        <ul>        
<li>планирование;</li>
<li>проектирование;</li>
<li>инвестиции в строительство;</li>
<li>формирование договорной и фактической стоимости работ;</li>
<li>классификация по всем видам работ.</li>
        </ul>
      </div>

      <p class="p3">
При расчете накладных расходов мы учитываем:
      </p>
      <div class="p4">
        <ul>        
<li>отраслевую направленность;</li>
<li>особенности ведения хозяйственной деятельности;</li>
<li>фактические показатели по статьям затрат и данными аналитического учета за предыдущий <span style="white-space: nowrap;">3-х</span> летний период.</li>
        </ul>
      </div>
      <p class="article-text">
Стоимость услуг Центра ценообразования за исполнение любых видов работ определяется индивидуально из расчета сложности и объемности выполняемых работ. Нашими специалистами будет детально рассмотрена предоставленная Вами документация, намечены пути решения с определением дорожной карты, сформированы сроки исполнения заказа.
      </p>
</div>
