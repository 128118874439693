import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import { Location } from "@angular/common";
import { srccsWebServiceDataSource } from "../model/srccsWebService.datasource";
import { CounterDirective } from "./counter.directive";
import { PhoneMaskingDirective } from "./phone.directive";
import { ThemePalette} from '@angular/material/core';
import { ProgressBarMode } from '@angular/material/progress-bar';

@Component({
  selector: 'captive',
  templateUrl: './captive.component.html',
  styleUrls: ['./captive.component.css']
})

export class CaptiveComponent {
  math = Math;
  public browserurl: string;
  public step: number = 0;
  public clientmac: string = "";
  public apmac: string = "";
  public clientphone: string = "";
  public callbackphone: string = "";
  public count=0;
  public showwait=false;
  public errortext: string = "";

  constructor(private router: Router, private location: Location, private ws: srccsWebServiceDataSource){
    this.browserurl = this.location.path(true);
  }

  r() {
    window.open('/guest/s/default?logok=1','_self');
  }

  ngOnInit() {
    if (this.browserurl == '/guest/s/default?logok=1') {
      this.step=4;
    } else if (this.browserurl.indexOf ('/guest/s/default?ap=') == 0) {
      var inputQuery = this.browserurl.split('?')[1].split('&');
      var params: { [index: string]: string } = {};
      inputQuery.forEach((value, index) => {
        var pair = value.split('=');
        params[pair[0]] = pair[1];         
      });
      if (params['ap']?.length == 17 && params['id']?.length == 17 && params['t']?.length > 0  && params['url']?.length > 0  && params['ssid']?.length > 0 ) {
        this.clientmac = params['id'];
        this.apmac = params['ap'];
        this.step = 1;         
      }
      if (this.clientmac == "") {
          this.router.navigate(["/"]);
      }
    }
  }

  public getCallbackPhone () {
    this.showwait=true;
    this.ws.requestPhone(this.clientmac, this.apmac, this.clientphone.replace(/\D/g, '')).subscribe((value) => 
      { 
        this.showwait=false; 
        if (value['status']=='ok') {
          this.step=2; 
          this.callbackphone = value['call_phone'];

          this.checkCaptiveLogon();

        } else { this.step=3; this.errortext = value['message']; }
      }, error => { this.callbackphone = ""; this.step=3; this.errortext = error.statusText; this.showwait=false; });
  }

  public checkCaptiveLogon () {
    this.ws.checkCaptiveLogon (this.clientmac).subscribe((value) => 
    { 
      if (value['status']=='1') {
          window.open('/guest/s/default?logok=1','_self');
          this.showwait=false; 
      } else { 
        this.showwait=true; 
        setTimeout(() => 
        {
          this.checkCaptiveLogon();      
        },5000);
        
      }
     }, error => { this.callbackphone = ""; this.step=3; this.errortext = error.statusText; this.showwait=false; });

  }

  public getPhoneLink () {
    return "tel:"+this.callbackphone;
  }

  public checkPhone () {
    return (this.clientphone.replace(/\D/g, '').length != 10);
  }

  public checkPhoneTime () {
    if (this.count==0) this.step=1;
  }

  public get callbackphonepretty() {
    return this.callbackphone.replace(/\D/g, '').replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(.*)/, '\+$1 ($2) $3-$4-$5');
  }
}
