import { Component, OnInit, Inject, Optional } from "@angular/core";
import { Router } from '@angular/router';
import { AppComponent } from './app.component';
import {RESPONSE} from '@nguniversal/express-engine/tokens';

@Component({
    selector: "Error404",
    template: ""
})

export class ErrorPageComponent {
  constructor(private appl: AppComponent, public router: Router, @Optional() @Inject(RESPONSE) private response) {}
 
  ngOnInit() {
    if (this.router.url)
      //window.open('/notfound?url=' + this.router.url,'_self'); // отладка

      //if (this.appl.isBrowser) { window.open('/errorpage?url=' + this.router.url,'_self'); }
      //else 

      if (!this.appl.isBrowser) this.response.status(404); 

      //window.open('https:///srccs.ru/404.html?url=' + this.router.url,'_self');
      //window.open('/notfound?url=' + this.router.url,'_self');
      //this.router.navigate(['/notfound?url=' + this.router.url]);
    //console.log ('error:' + this.router.url);
  }
}
