<div class="main-content fade-in-content"> 
  <div class="container-fluid">
    <div class="row justify-content-center align-items-center">
      <div class="col px-2 py-2 keep-width-over900 text-center">
        <div class="bg-white shadow-sm article-holder">
          <div class="row h-100 holder-paddings m-0">
            <div class="col">
              <div *ngIf="step==1" class="">
                <div class="texth1">Добро пожаловать в гостевую сеть нашей компании!</div>
                <div class="texth2">Чтобы мы могли предоставить Вам доступ в Интернет, введите, пожалуйста, номер Вашего телефона.</div>
                <span class="phoneprefix">+7</span><input autofocus type="tel" class="input-primary" inputPhoneMasking [(ngModel)]="clientphone" />
                <div>
                  <button [disabled]="showwait || checkPhone()" class="btn btn-primary btn-primary1" (click)="getCallbackPhone()">Продолжить</button>
                </div>
                <div class="text">
                  После нажатия кнопки "Продолжить" Вам будет предложен номер телефона 
                  на который необходимо сделать звонок в течение пяти минут. 
                  Звонок сбрасывается сразу при поступлении (как только вы услышите гудки), 
                  и поэтому бесплатен для Вас.
                </div>
              </div>
              <div *ngIf="step==2">
                <div class="texth2">Для завершения процедуры получения доступа в Интернет Вам необходимо позвонить по телефону</div>  
                <div class="textl"><a [href] ="getPhoneLink() | safeUrl">{{ callbackphonepretty }}</a></div>
                <div class="texth3">Осталось времени для совершения звонка: 
                  <ng-container [counter]="301" (value)="count = $event; checkPhoneTime()">
                    <span class="text-counter"> {{math.floor(count/60)}}:{{(count % 60).toString().padStart(2,'0')}} </span>
                  </ng-container>
                </div>
                <div class="text">
                  Звонок сбрасывается сразу при поступлении (как только вы услышите гудки), 
                  и поэтому бесплатен для Вас.
                </div>
              </div>
              <div *ngIf="step==3">
                <div class="texth2">
                  :( Нам очень жаль, но похоже сервис проверки номера телефона сейчас не доступен.
                  <!-- Ошибка: {{ errortext }} -->
                </div>
                <div class="texth2">
                  Попробуйте подключиться позже.
                  <!-- Ошибка: {{ errortext }} -->
                </div>
              </div>
              <div *ngIf="step==4" class="texth1">
                Поздравляем! Вам предоставлен доступ в Интернет.
              </div>
              <div *ngIf="step==2 || step==3">
                <div style="text-align: left" >
                  <button class="btn btn-primary btn-primary2" (click)="step=1">< Назад</button>
                </div>
              </div>
            </div>
          </div>
          <div class="row m-0">
            <div class="col">
              <div class="px-0 py-0">
                <div [class.show]="showwait" [class.hide]="!showwait">
                  <mat-progress-bar mode="query" color='accent' style="height:6px;"></mat-progress-bar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>      
</div>
