<div class="fade-in-constent">
      <h2>География наших работ</h2>

      <div class="row w-100 mx-0 mt-3">
        <div class="col-sm-8 col-12 px-0">
          <div class="p4 text-center"><strong>г. Салехард, Ямало-Ненецкий АО</strong></div>
          <p class="article-text">
Проведена переоценка основных фондов объектов энергетики, аэропорта. Разработана логистическая зональная схема доставки материальных ресурсов до места назначения с учетом географического расположения региона, условий доставки от завода-поставщика до потребителя с делением на 5 зон. Сотрудничество продолжается на сегодняшний день.
          </p>
        </div>
        <div class="col-sm-4 col-12 pl-3 pr-0 text-center align-self-center">
          <img class="" style="margin: 0px 0px 0px 0px; width:100%" src="../../images/gallery/YNAO_salehard.jpg" />
        </div>
      </div>

      <div class="row w-100 mx-0 mt-5">
        <div class="col-sm-4 col-12 pl-0 pr-3 text-center align-self-center">
          <img class="" style="margin: 0px 0px 0px 0px; width:100%" src="../../images/gallery/chukotka.jpg" />
        </div>
        <div class="col-sm-8 col-12 px-0 ">
          <div class="p4 text-center"><strong>Чукотский автономный округ</strong></div>
          <p class="article-text">
Сотрудничество с 1999 г. Разработана территориальная сметно-нормативная база, логистическая схема доставки ресурсов с делением на 2 зоны, в настоящее время ведется разработка РТМ. Наше сотрудничество продолжается.        
          </p>
        </div>
      </div>

      <div class="row w-100 mx-0 mt-5">
        <div class="col-8 px-0">
          <div class="p4 text-center"><strong>г. Норильск, г. Талнах</strong></div>
          <p class="article-text">
С 2004 года с городом Норильск и соседними городами нас связываем градообразующее предприятие ЗФ ПАО «ГМК «Норильский никель», для которого была сформирована фирменная сметно-нормативная база по строительству и ремонту оборудования, а также многочисленная методическая документация. Сопровождение Фирменной СНБ нами осуществляется по настоящее время.
          </p>
          <div class="text-center align-self-center">          
            <img class="" style="width:50%" src="../../images/gallery/norilskgate.jpg" />
          </div>
        </div>
        <div class="col-4 pl-3 pr-0 text-center align-self-center">
          <img class="" style="width:100%" src="../../images/gallery/talnah.jpg" />
        </div>
      </div>

      <div class="row w-100 mx-0 mt-5">
        <div class="col-4 pl-0 pr-3 text-center align-self-center">
          <img class="" style="margin: 0px 0px 0px 0px; width:100%" src="../../images/gallery/kolima.jpg" />
        </div>
        <div class="col-8 px-0 ">
          <div class="p4 text-center"><strong>Магаданская область</strong></div>
          <p class="article-text">
Разработана территориальная сметно-нормативная база для Магаданской области, проводится оценка рыночной стоимости материалов и оборудования для рудника АО «Полюс».
          </p>
        </div>
      </div>

      <div class="row w-100 mx-0 mt-5">
        <div class="col-8 px-0">
          <div class="p4 text-center"><strong>г. Магнитогорск</strong></div>
          <p class="article-text">
Разработка сметных нормативов на ремонт оборудования сталепрокатного производства ПАО «Магнитогорский металлургический комбинат».
          </p>
        </div>
        <div class="col-4 pl-3 pr-0 text-center align-self-center">
          <img class="" style="margin: 0px 0px 0px 0px; width:100%" src="../../images/gallery/magnitogorsk.jpg" />
        </div>
      </div>

      <h2 class="mt-5">Международное сотрудничество</h2>

      <div class="row w-100 mx-0 mt-3">
        <div class="col-8 px-0">
          <div class="p4 text-center"><strong>г. Лондон, Великобритания, июль 1997 г.</strong></div>
          <p class="article-text">
Российская делегация (г. Омск, г. Москва, г. Санкт-Петербург, г. Кемерово, г. Воронеж) в качестве почетных гостей на ежегодной встрече с членами международной ассоциации инженеров-ценовиков. Зал, вмещающий 300 человек, стоя бурными аплодисментами приветствовал Российскую делегацию.
          </p>
        </div>
        <div class="col-4 pl-3 pr-0 text-center align-self-center">
          <img class="" style="margin: 0px 0px 0px 0px; width:100%" src="../../images/gallery/london.jpg" />
        </div>
      </div>

      <div class="row w-100 mx-0 mt-5">
        <div class="col-4 pl-0 pr-3 text-center align-self-center">
          <img class="" style="margin: 0px 0px 0px 0px; width:100%" src="../../images/gallery/conference.jpg" />
        </div>
        <div class="col-8 px-0 ">
          <div class="p4 text-center"><strong>г. Омск, Россия, май 1998 г.</strong></div>
          <p class="article-text">
Коллеги из Великобритании с деловым визитом в г. Омске.
          </p>
        </div>
      </div>

      <div class="row w-100 mx-0 mt-5">
        <div class="col-8 px-0">
          <div class="p4 text-center"><strong>XVI Форум межрегионального сотрудничества России и Казахстана 6-7 ноября 2019 г. в Омске</strong></div>
          <p class="article-text">
Российско-казахстанский межрегиональный форум представляет собой эффективную площадку для укрепления двусторонних отношений, диверсификации деловых контактов и общения представителей власти и бизнеса двух государств.
          </p>
        </div>
        <div class="col-4 pl-3 pr-0 text-center align-self-center">
          <img class="" style="margin: 0px 0px 0px 0px; width:100%" src="../../images/gallery/forumkaz.jpg" />
        </div>
      </div>

      <h2 class="mt-5">Региональные встречи и совещания</h2>

      <div class="row w-100 mx-0 mt-3">
        <div class="col-4 pl-0 pr-3 text-center align-self-center">
          <img class="" style="margin: 0px 0px 0px 0px; width:100%" src="../../images/gallery/admomsk.jpg" />
        </div>
        <div class="col-8 px-0 ">
          <p class="article-text">
Совещание в администрации г. Омска.        
          </p>
        </div>
      </div>

      <h2 class="mt-5">На строительных объектах г. Омска</h2>

      <div class="row w-100 mx-0 mt-3">
        <div class="col-8 px-0">
          <p class="article-text">
Выполнена экспертиза сметной документации «под ключ» по объекту «Строительство совмещенного с метрополитеном моста через р. Иртыш. ОАО «Омскстроймост» Руководители Правительства Омской области, ОАО «Омскстроймост», ООО «Строительный Трест-№1» и Центра ценообразования.
          </p>
          <div class="text-center align-self-center">          
            <img class="" style="width:50%" src="../../images/gallery/metromost2.jpg" />
          </div>
        </div>
        <div class="col-4 pl-3 pr-0 text-center align-self-center">
          <img class="" style="width:100%" src="../../images/gallery/metromost1.jpg" />
        </div>
      </div>

      <h2 class="mt-5">Вручение почетных наград</h2>

      <div class="row w-100 mx-0 mt-3">
        <div class="col-8 px-0">
          <p class="article-text">
Министр строительства, транспорта и дорожного хозяйства Омской области А.А. Заев вручает государственную награду Знак отличия «За служение Омской области 2 степени» генеральному директору Фадеевой Г.В.
          </p>
        </div>
        <div class="col-4 pl-3 pr-0 text-center align-self-center">
          <img class="" style="margin: 0px 0px 0px 0px; width:100%" src="../../images/gallery/nagrada1.jpg" />
        </div>
      </div>

      <div class="row w-100 mx-0 mt-3">
        <div class="col-4 pl-0 pr-3 text-center align-self-center">
          <img class="" style="margin: 0px 0px 0px 0px; width:100%" src="../../images/gallery/nagrada2.jpg" />
        </div>
        <div class="col-8 px-0 ">
          <p class="article-text">
Губернатор Омской области А.Л. Бурков вручает Галине Васильевне Государственную награду Медаль ордена «За заслуги перед Отечеством» 2 степени (указ Президента РФ от 27.12.2019 №622).
          </p>
        </div>
      </div>

      <h2 class="mt-5">Командировка в Китай и его история в лицах</h2>

      <div class="row w-100 mx-0 mt-3">
        <div class="col pl-3 pr-0 text-center align-self-center">
          <img class="" style="margin: 0px 0px 0px 0px; width:35%" src="../../images/gallery/china.jpg" />
        </div>
      </div>

      <h2 class="mt-5">Командировка в Верхнюю Пышму</h2>

      <div class="row w-100 mx-0 mt-3">
        <div class="col pl-3 pr-0 text-center align-self-center">
          <img class="" style="margin: 0px 0px 0px 0px; width:75%" src="../../images/gallery/pishma1.jpg" />
        </div>
        <div class="col pl-3 pr-0 text-center align-self-center">
          <img class="" style="margin: 0px 0px 0px 0px; width:75%" src="../../images/gallery/pishma2.jpg" />
        </div>
      </div>

      <h2 class="mt-5">Клуб «Реформа» для руководителей организаций при Центре ценообразования</h2>

      <div class="row w-100 mx-0 mt-3">
        <div class="col pl-3 pr-0 text-center align-self-center">
          <img class="" style="margin: 0px 0px 0px 0px; width:40%" src="../../images/gallery/reformaclub.jpg" />
        </div>
      </div>

      <h2 class="mt-5">Центр ценообразования</h2>
      <div class="row w-100 mx-0 mt-3">
        <div class="col pl-3 pr-0 text-center align-self-center">
          <img class="" style="margin: 0px 0px 0px 0px; width:82%" src="../../images/gallery/center1.jpg" />
        </div>
        <div class="col pl-3 pr-0 text-center align-self-center">
          <img class="" style="margin: 0px 0px 0px 0px; width:70%" src="../../images/gallery/center3.jpg" />
        </div>
      </div>
      <div class="row w-100 mx-0 mt-5">
        <div class="col pl-3 pr-0 text-center align-self-center">
          <img class="" style="margin: 0px 0px 0px 0px; width:40%" src="../../images/gallery/center2.jpg" />
        </div>
      </div>


</div>