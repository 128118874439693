<div class="fade-in-content">
      <p class="article-header">
      Мониторинг
      </p>
      <p class="h4 mb-0 text-center">
ПРАЙС-ЛИСТ
      </p>
      <p class="p3 mt-0 text-center">
на услуги по конъюнктурному анализу среднерыночной стоимости материалов и оборудования
      </p>
      <div class="table-price table-price-indexes">
      <table>
        <tr>
          <th>№</th>
          <th>Наименование</th>
          <th>Цена услуги за единицу, &#8381;</th>
          <th>Срок проведения анализа от, дней</th>
        </tr>
        <tr>
          <td *ngIf="price.length==0" colspan=4>Выполняется загрузка данных...</td>
        </tr>
        <tr *ngFor="let item of price; let i = index;">
          <td>{{ i+1 }}</td>
          <td [innerHTML]="item.name | safeHtml">
          </td>
          <td [innerHTML]="item.cost | numberSpaceSeparator | safeHtml"></td>
          <td>{{ item.duration }}</td>
        </tr>
      </table>
      </div>
</div>
