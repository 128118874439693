import { Injectable } from "@angular/core";
import { PriceIndexesItem } from "./PriceIndexesItem.model";
import { srccsWebServiceDataSource } from "./srccsWebService.datasource";

@Injectable({providedIn: 'root'})
export class PriceIndexesRepository {
    private price1: PriceIndexesItem[] = [];
    private price2: PriceIndexesItem[] = [];
    private price3: PriceIndexesItem[] = [];

    constructor(private api: srccsWebServiceDataSource) {
        api.getData("price", "indexes", "1").subscribe(data => {
            this.price1 = srccsWebServiceDataSource.parseTooltip(data);
        });
        api.getData("price", "indexes", "2").subscribe(data => {
            this.price2 = srccsWebServiceDataSource.parseTooltip(data);
        });
        api.getData("price", "indexes", "3").subscribe(data => {
            this.price3 = srccsWebServiceDataSource.parseTooltip(data);
        });
    }


    getPrice(tableId: number): PriceIndexesItem[] {
      if (tableId == 0)
        return this.price1;
      if (tableId == 1)
        return this.price2;
      if (tableId == 2)
        return this.price3;
    }
}
