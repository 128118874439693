<div class="fade-in-content">
  <h2>
      Сметы
  </h2>
  <p class="article-text">При проведении строительства или ремонта любого объекта проверка сметной стоимости является очень важным моментом. Причина в том, что очень часто возможны разногласия между заказчиком и подрядчиком. Чтобы избежать этого, лучше всего прибегнуть к независимой экспертной оценке — это поможет не только удостовериться в подлинности предоставленной информации, но также и предотвратить в дальнейшем разбирательства между сторонами сделки. Но чтобы определение действительно было достоверным, 
  важным условием является ответственный подход при выборе компании в качестве экспертной организации. 
  Наш Центр ценообразования обладает всеми юридическими правами на данный вид деятельности и располагает штатом высококлассных специалистов.</p>  
  <div class="p3"> 
                  <ul>
                    <li>
                      Работаем во всех обновленных базах: ФЕР, базы ТЕР всех регионов России, а так же по ведомственным нормативам.
                    </li>
                    <li>
                      Разработка сметной документации любого уровня на все виды строительно-монтажных работ, включая электромонтажные работы, КИП, СКУД, СКС, инженерные сети, монтаж оборудования, пусконаладочные работы, эксплуатация зданий и сооружений, ПИР, геодезия и др. виды работ.
                    </li>
                    <li>
                      Разработка сметной документации на основании технических паспортов, актов осмотра, чертежей, дефектных ведомостей и т. д.
                    </li>
                    <li>
                      Выезд специалиста на объект, составление дефектной ведомости с подсчетом объемов работ.
                    </li>
                    <li>
                      Формирование актов выполненных работ формы КС-2; КС-3.
                    </li>
                    <li>
                      Проведение экспертизы сметной документации в части проверки достоверности определения сметной стоимости с выдачей Заключения с приложением сертификатов организации, дипломов экспертов.
                    </li>
                  </ul>
  </div>
  <p class="p3 color-bl-me text-center">
Стоимость услуг по экспертизе, разработке, проверке сметной документации зависит от вида выполняемых работ и количества позиций в смете. Минимальная стоимость 2700 руб.
  </p>
  <p class="p4">
      <a class="link-inline" href="files/letter_for_estimate.docx">Образец письма для проверки и составления сметного расчета.</a>
  </p>
</div>
