import { Component } from "@angular/core";
import { AppComponent } from './app.component';
import { OString } from "./model/OString.model";
import { NamedUrl } from "./model/NamedUrl.model";

import { HomeSliderImagesRepository } from "./model/HomeSliderImages.repository";

declare var $:any;

@Component({
    selector: "home",
    templateUrl: "home.component.html"
})

export class HomeComponent {
    constructor(private appl: AppComponent, private repository: HomeSliderImagesRepository) {}

    ngOnInit() {
        if (this.appl.isBrowser) {
          $(document).ready(function() {
            $('.carousel').carousel();
          });
        }
    }

    get sliderimages(): NamedUrl[] {
        return this.repository.getImages();
    }

}
