<div class="fade-in-content">
  <h4>
АО «ЕВРАЗ Объединенный Западно-Сибирский металлургический комбинат»
  </h4>
  <p class="article-text">
Разработаны технологические карты на текущий ремонт оборудования рельсобалочного цеха.
  </p>

  <h4>
АО «Газпромнефть-ОНПЗ»
  </h4>
  <p class="article-text">
Разработано дополнение к БД оборудования и нормативов ТОРО НПЗ.
  </p>

  <h4>
АО «Газпромнефть-Каталитические системы»
  </h4>
  <p class="article-text">
Выполнены локальные сметные расчеты по объекту «Строительство производства катализаторов».
  </p>

  <h4>
ЗФ ПАО «ГМК «Норильский никель» 
  </h4>
  <p class="article-text mb-0">
Разработаны:
  </p>
  <div class="article-text"><ul>        
<li>фирменная сметно-нормативная база на ремонт оборудования;</li>
<li>фирменная сметно-нормативная база на строительство;</li>
<li>методика определения сметных норм и расценок на эксплуатацию строительных машин, в т. ч. зарубежного производства;</li>
<li>методика расчета фирменного норматива транспортных затрат на перевозку строительных материалов, изделий, конструкций и оборудования;</li>
<li>методология расчета поправки на дополнительные транспортные расходы по доставке материалов, изделий и конструкций авиатранспортом;</li>
<li>методика расчёта нормативов заготовительно-складских расходов в том числе снабженческо-сбытовых;</li>
<li>методика по определению стоимости проектных работ (Книга I и II);</li>
<li>методические указания по определению величины накладных расходов (части I и II);</li>
<li>методические указания по определению величины сметной прибыли;</li>
<li>указания по применению фирменных единичных расценок на пусконаладочные работы (ФирЕРп-2004);</li>
<li>сборник сметных норм затрат на строительство временных зданий и сооружений при производстве строительно-монтажных и ремонтно-строительных работ;</li>
<li>сборник сметных норм дополнительных затрат при производстве строительно-монтажных и ремонтно-строительных работ в зимнее время;</li>
<li>порядок определения стоимости строительной продукции;</li>
<li>порядок пересчета сметной документации в соответствии с Фирменной сметно-нормативной базой (ФирСНБ-2004) по объектам строительства, для которых сметная документация разработана в ценах 1984 г. и ведется строительство;</li>
<li>методика определения затрат, включаемых в сводные сметные расчеты стоимости строительства;</li>
<li>сборник калькуляций транспортных расходов на перевозку 1 тонны строительных материалов, изделий и конструкций от складов предприятия "Единое складское хозяйство" и местных предприятий до приобъектных складов строительства с учетом средневзвешенных расстояний;</li>
<li>транспортная схема доставки материальных ресурсов на объекты строительства;</li>
<li>методика расчета индексов изменения сметной стоимости по видам работ, статьям затрат, конструктивным элементам.</li>
  </ul></div>

  <h4>
ПАО «Россети ФСК ЕЭС»
  </h4>
  <p class="article-text mb-0">
Разработаны укрупненные/пооперационные расценки по ремонту, техническому обслуживанию и диагностике технического состояния электрических сетей, в составе:
  </p>
  <div class="article-text"><ul>        
<li>ремонт и техническое обслуживание Воздушных линий электропередачи напряжением 35-1500 кВ;</li>
<li>ремонт и техническое обслуживание Оборудования подстанций напряжением 35-750 кВ;</li>
<li>ремонт и техническое обслуживание Воздушных линий, трансформаторных подстанций и распределительных пунктов напряжением 0,38-20 кВ;</li>
<li>ремонт и техническое обслуживание Воздушных линий электропередачи под напряжением;</li>
<li>ремонт и техническое обслуживание Релейной защиты и автоматики.</li>
  </ul></div>

  <h4>
АК «АЛРОСА» (ПАО) 
  </h4>
  <p class="article-text mb-0">
Разработаны:
  </p>
  <div class="article-text"><ul>        
<li>фирменная сметно-нормативная база на ремонт оборудования и транспорта;</li>
<li>положение «О порядке планирования и контроля ремонтных работ в АК «АЛРОСА» (ПАО)» с методикой определения восстановительной стоимости основных средств»;</li>
<li>удельные нормы расхода топлива карьерными автосамосвалами Terex TR100 дифференцированных по горизонтам отработки карьера «Нюрбинский» и программа автоматизированного расчета.</li>
  </ul></div>

  <h4>
ПАО «Магнитогорский металлургический комбинат»
  </h4>
  <p class="article-text mb-0">
Разработаны сборники сметных норм и расценок на ремонт оборудования:
  </p>
  <div class="article-text"><ul>        
<li>гидросмазочного и пневматического;</li>
<li>ЭСПЦ и ККЦ;</li>
<li>сортовых станов;</li>
<li>стана 5000 г/п;</li>
<li>стана 2000 х/п.</li>
  </ul></div>

  <h4>
ОАО «Павловск Неруд»
  </h4>
  <p class="article-text">
Разработаны сметные нормы на ремонт оборудования и транспорта.
  </p>

  <h4>
ПАО «Новолипецкий металлургический комбинат» 
  </h4>
  <p class="article-text">
Выполнена проверка и корректировка норм трудозатрат на работы по ремонту и обслуживанию оборудования, зданий и сооружений.
  </p>

  <h4>
ЗАО «Полюс» 
  </h4>
  <p class="article-text mb-0">
Разработаны:
  </p>
  <div class="article-text"><ul>        
<li>фирменная сметно-нормативная база на ремонт оборудования;</li>
<li>анализ систем классификации и сметно-нормативной базы на ремонт и техническое обслуживание основных средств Красноярской бизнес-единицы ЗАО «Полюс».</li>
  </ul></div>

  <h4>
ОАО «РЖД»
  </h4>
  <p class="article-text mb-0">
Разработаны:
  </p>
  <div class="article-text"><ul>        
<li>сметно-нормативная база для определения стоимости сервисного обслуживания технологического и инженерного оборудования для обслуживания высокоскоростных электропоездов «Сапсан», «Ласточка»;</li>
<li>расчет стоимости сервисного обслуживания технологического оборудования и инженерных систем для обслуживания высокоскоростных электропоездов «Дезиро» в депо станции «Адлер»;</li>
<li>расчет стоимости сервисного обслуживания технологического оборудования и инженерных систем для обслуживания высокоскоростных электропоездов «Сапсан» в депо станции «Санкт-Петербург-Московское».</li>
  </ul></div>

  <h4>
ПАО «Россети Сибирь», ПАО «Россети Юг», АО «Россети Тюмень»
  </h4>
  <p class="article-text mb-0">
Разработана Фирменная сметно-нормативная база на ремонт энергетического оборудования.
  </p>
  <p class="article-text mb-0">
Разработаны элементно-сметные нормы (укрупненные, пооперационные), в составе:
  </p>
  <div class="article-text"><ul class="mb-0">        
<li>ремонт и техническое обслуживание трансформаторов и электродвигателей;</li>
<li>ремонт и техническое обслуживание электрооборудования трансформаторных подстанций и распределительных устройств;</li>
<li>ремонт и техническое обслуживание линий электропередачи ВЛ 0,38-330 кВ;</li>
<li>испытания электротехнических установок и диагностическое обследование электрооборудования и ВЛ, проведение химико-физических анализов, определение теплового состояния контактных соединений электрооборудования и линий электропередачи с помощью тепловизора;</li>
<li>ремонт и техническое обслуживание устройств релейной защиты и противоаварийной автоматики, наладка электротехнических установок;</li>
<li>ремонт и техническое обслуживание средств диспетчерского и технологического управления (средств связи и телемеханики).</li>
  </ul></div>
  <p class="article-text mb-0">
Для расчета единичных расценок разработаны:
  </p>
  <div class="article-text"><ul>        
<li>методика расчета индексов изменения сметной стоимости по видам работ, статьям затрат, конструктивным элементам;</li>
<li>сборник сметных расценок на эксплуатацию машин и автотранспортных средств;</li>
<li>сборник сметных цен на перевозки грузов;</li>
<li>сборник сметных цен на материалы, изделия, запасные части и конструкции для выполнения работ по ремонту и техническому обслуживанию электротехнических установок</li>
  </ul></div>
</div>