import { Injectable } from "@angular/core";
import { NamedUrl } from "./NamedUrl.model";
import { srccsWebServiceDataSource } from "./srccsWebService.datasource";

import { NewsItem } from "./NewsItem.model";
import { LocationSide } from "./LocationSide.model";

@Injectable({providedIn: 'root'})
export class NewsRepository {
    private news: NewsItem[] = [];
    private newsSrv: NewsItem[] = [];

    constructor(private api: srccsWebServiceDataSource) {
        api.getData("news").subscribe(data => {
            this.newsSrv = data;
            for (let i = 0; i < this.newsSrv.length; i++) {
              this.newsSrv[i].expired = new Date (this.newsSrv[i].expired);
              this.newsSrv[i].published = new Date (this.newsSrv[i].published);
              if (this.newsSrv[i].head.length>512) this.newsSrv[i].head = "Wrong head";
              if (this.newsSrv[i].body.length>2048) this.newsSrv[i].body = "Wrong body";
              if (this.newsSrv[i].imageLocation != null)
                switch (this.newsSrv[i].imageLocation.toString()) {
                  case "Left":
                    this.newsSrv[i].imageLocation = LocationSide.Left;
                    break;
                  case "Right":
                    this.newsSrv[i].imageLocation = LocationSide.Right;
                    break;
                  default:
                    this.newsSrv[i].imageLocation = LocationSide.None;
                    break;
              } else
                    this.newsSrv[i].imageLocation = LocationSide.None;
            }
        });

    }


    getNews(): NewsItem[] {
        return this.newsSrv;
    }

}
