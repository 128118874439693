import { Routes, RouterModule, ExtraOptions } from "@angular/router";

import { HomeComponent } from "./home.component";
import { NewsComponent } from "./news.component";
import { SectionsComponent } from "./sections.component";
import { ErrorPageComponent } from "./errorpage.component";
import { NotFoundComponent } from "./notfound.component";

import { ServicesSmetsComponent } from "./services/smets.component";
import { ServicesSmetsProgsComponent } from "./services/smets/progs.component";
import { ServicesSmetsIndexesComponent } from "./services/smets/indexes.component";
import { ServicesNormsComponent } from "./services/norms.component";
import { ServicesResourcesComponent } from "./services/resources.component";
import { ServicesResourcesMonitoringComponent } from "././services/resources/monitoring.component";
import { ServicesNormsBuildingComponent } from "./services/norms/building.component";
import { ServicesNormsRepairsComponent } from "./services/norms/repairs.component";
import { ServicesNormsToirComponent } from "./services/norms/toir.component";
import { ServicesNormsAsutpComponent } from "./services/norms/asutp.component";
import { ServicesTrainingComponent } from "./services/training.component";
import { ServicesTrainingCourseComponent } from "./services/training/course.component";
import { ServicesTrainingSeminarComponent } from "./services/training/seminar.component";

import { AboutHistoryComponent } from "./about/history.component";
import { AboutPortfolioComponent } from "./about/portfolio.component";
import { AboutAwardsComponent } from "./about/awards.component";
import { AboutHeadsComponent } from "./about/heads.component";
import { AboutGalleryComponent } from "./about/gallery.component";

import { ContactsPrimaryComponent } from "./contacts/primary.component";
import { ContactsDetailedComponent } from "./contacts/detailed.component";

import { CaptiveComponent } from "./captive/captive.component";

const aboutChildRoutes: Routes = [
    { path: "", redirectTo: 'history', pathMatch: 'full' },
    { path: "history", component: AboutHistoryComponent
      ,data: { 
        title: 'Краткая информация о компании',
        description:''
      }
     },
    { path: "portfolio", component: AboutPortfolioComponent 
      ,data: { 
        title: 'Партнеры',
        description:''
      }
    },
    { path: "heads", component: AboutHeadsComponent 
      ,data: { 
        title: 'Руководство',
        description:''
      }
    },
    { path: "awards", component: AboutAwardsComponent 
      ,data: { 
        title: 'Награды и достижения',
        description:''
      }
    },
    { path: "gallery", component: AboutGalleryComponent 
      ,data: { 
        title: 'Фотогалерея',
        description:''
      }
    }
    //,{ path: "**", component: NotFoundComponent }
];

const servicesChildRoutes: Routes = [
    { path: "", redirectTo: "smets", pathMatch: 'full' },
    { path: "smets", children:
      [
        { path: "", component: ServicesSmetsComponent
          ,data: { 
            title: 'Разработка и экспертиза сметной документации',
            description:''
          }
        },
        { path: "progs", component: ServicesSmetsProgsComponent 
          ,data: { 
            title: 'Гранд-смета',
            description:''
          }
        },
        { path: "indexes", component: ServicesSmetsIndexesComponent 
          ,data: { 
            title: 'Бюллетень информационных материалов, индексы и сметные цены',
            description:''
          }
        }
        //,{ path: "**", component: NotFoundComponent }
      ]    
    },
    { path: "norms", children:
      [
        { path: "", redirectTo: 'building', pathMatch: 'full' },
        { path: "building", component: ServicesNormsBuildingComponent 
          ,data: { 
            title: 'Корпоративные базы и ресурсные нормы',
            description:''
          }
        },
        { path: "repairs", component: ServicesNormsRepairsComponent 
          ,data: { 
            title: 'Сметно-нормативная базя на ремонт оборудования',
            description:''
          }
        },
        { path: "toir", component: ServicesNormsToirComponent
          ,data: { 
              title: 'Техническое обслуживание и ремонт, технологические карты',
              description:''
          }
        },
        { path: "asutp", component: ServicesNormsAsutpComponent
          ,data: { 
              title: 'АСУ ТП',
              description:''
          }
        }
        //,{ path: "**", redirectTo: "building" }
        //,{ path: "**", component: NotFoundComponent }
      ]
    },
    { path: "resources", children:
      [ 
        { path: "", component: ServicesResourcesComponent 
          ,data: { 
              title: 'Ресурсы',
              description:''
          }
        },
        { path: "monitoring", component: ServicesResourcesMonitoringComponent 
          ,data: { 
              title: 'Мониторинг (конъюнктурный анализ) среднерыночной стоимости материалов и оборудования',
              description:''
          }
        }
      ]
    },
    { path: "training", children:
      [
        { path: "", redirectTo: 'course', pathMatch: 'full' },
        { path: "course", component: ServicesTrainingCourseComponent 
          ,data: { 
              title: 'Курсы повышения квалификации инженеров стоимостного инжиниринга',
              description:''
          }
        },
        { path: "seminar", component: ServicesTrainingSeminarComponent
          ,data: { 
              title: 'Семинар. ГРАНД-Смета. Сметно-нормативная база',
              description:''
          }
        }
      ]
    }
];

const contactsChildRoutes: Routes = [
    { path: "", component: ContactsPrimaryComponent },
    { path: "detailed", component: ContactsDetailedComponent 
      ,data: { 
        title: 'Дополнительные контакты',
        description:''
      }
    }
];

const routes: Routes = [  
  { path: "", component: HomeComponent, 
    data: { urlname: 'Главная',
      title: 'ЗАО \"Сибирский центр ценообразования в строительстве, промышленности и энергетике\"',
      description:''      
    } 
  },
  { path: "home", redirectTo: "" },
  { path: "guest/s/default", component: CaptiveComponent },
  { path: "errorpage", component: ErrorPageComponent },
  { path: "notfound", component: NotFoundComponent
      ,data: { 
        title: '404 - Page Not Found',
        description:''
      } },
  { path: "news", component: NewsComponent, 
    data: { 
      title: 'Новости',
      description:''
    }
  },
  { path: "services", component: SectionsComponent, children: servicesChildRoutes },
  { path: "about", component: SectionsComponent, children: aboutChildRoutes },
  { path: "contacts", component: SectionsComponent, 
    data: { 
      title: 'Контакты',
      description:''
    }  
    , children: contactsChildRoutes 
  }
  ,{ path: "**", component: NotFoundComponent }
  //,{ path: "**", redirectTo: "errorpage" }
]           

const routerOptions: ExtraOptions = {
  initialNavigation: 'enabled',
  scrollPositionRestoration: 'enabled',
  useHash: false,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload'
};

export const routing = RouterModule.forRoot(routes, routerOptions);
