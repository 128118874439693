<div class="fade-in-content">
      <p class="article-header">
      Программные продукты
      </p>
      <div class="h4 w-100 row">
        <div class="col-6 text-left align-self-end">ПК "Гранд-смета"</div>
        <div class="col-6 text-right"><a class="image-link" target="_blank" href="https://www.grandsmeta.ru/"><img class="img-fluid" src="../images/logo_grand.png" /></a></div>
      </div>
     
      <p class="p3">
Разработка сметной документации любого уровня 
      </p>
      <p class="quote">
Разработка сметной документации базисным, базисно-индексным, ресурсным, ресурсно-индексным, базисно-компенсационным методом. Возможность сочетания методов расчета в одном документе и сравнение итогов.      
      </p>
      <p class="p3">
Автоматическое формирование смет на основе выполненных актов работ
      </p>
      <p class="p3">
Объектные сметные расчеты (сметы)
      </p>
      <p class="quote">
С возможностью автоматического создания на основе локальных расчетов, составленных в программе. С автоматическим распределением затрат по главам и синхронизацией данных при их изменении. Расчет показателя единичной стоимости.
      </p>
      <p class="p3">
Автоматическое создание сводной ресурсной ведомости
      </p>
      <p class="quote">
Создание по выбранным актам выполненных работ. Формирование ведомости ресурсов на остаток выполненных работ.
      </p>

<p class="p3">
Системные требования:
</p>
<div class="p4 ml-1">
<ul>
<li>свободное место на жестком диске не менее 2 Гб + 200 Мб для каждой нормативной базы;</li>
<li>для базы ГРАНД-СтройМатериалы – не менее 15 Гб;</li>
<li>операционная система: Windows 8.1, 10 (64-х разрядные). Серверные ОС не рекомендуется использовать для запуска локальной версии;</li>
<li>процессор не ниже 2 ГГц;</li>
<li>оперативная память не менее 4 Гб;</li>
<li>разрешение экрана не менее 1024х768 (рекомендуется от 1920х1080, 16:9);</li>
<li>свободный порт USB.</li>
</ul>
</div>
      <p class="p3 text-center mt-4">
        ПРАЙС-ЛИСТ
      </p>
      <div class="table-price table-price-grand">
      <table>
        <tr>
          <th>№</th>
          <th>Артикул</th>
          <th>Наименование программного комплекса</th>
          <th>Стоимость, &#8381;</th>
        </tr>
        <tr>
          <td *ngIf="price.length==0" colspan=4>Выполняется загрузка данных...</td>
        </tr>
        <tr *ngFor="let item of price; let i = index;">
          <td>{{ i+1 }}</td>
          <td>{{ item.id }}</td>
          <td [innerHTML]="item.name | safeHtml">
          </td>
          <td [innerHTML]="item.cost | numberSpaceSeparator | safeHtml"></td>
        </tr>
      </table>
      </div>
      <br/>
<p class="p3">Примечание:</p>
<div class="p4">
<ul>
<li>НДС не облагается;</li>
<li>права на использование программ для ЭВМ и баз данных предоставляются на условиях простой (неисключительной) лицензии;</li>
<li>в стоимость не включены услуги по выезду специалиста к заказчику;</li>
<li>данное предложение не является публичной офертой.</li>
</ul>
</div>

<p class="article-text">
<strong>ПК "ГРАНД-Смета"</strong> включает в себя программу для составления смет и базу данных "Электронная библиотека сметчика".
</p>

<p class="article-text">
<strong>ПК "ГРАНД-Смета" "Флеш"</strong> - мобильный вариант ПК "ГРАНД-Смета", записанный на специальное устройство (флеш-ключ), которое одновременно выполняет роль электронного ключа защиты и флеш-накопителя. При этом программа не требует установки на стационарный компьютер и запускается непосредственно с флеш-ключа.
</p>

<div class="article-text">
<strong>База данных "Электронная библиотека сметчика"</strong> включает документы по ценообразованию в строительстве, в том числе:
<ul>
<li>справочная литература и учебные пособия по сметному делу;</li>
<li>методические указания и рекомендации, инструкции и технические регламенты;</li>
<li>периодические издания по ценообразованию в строительстве;</li>
<li>консультации и разъяснения специалистов по сметному делу;</li>
<li>документы и разъяснения по саморегулированию в строительстве;</li>
<li>руководящие документы органов власти, законы, кодексы, указы Президента и постановления Правительства РФ;</li>
<li>нормативные акты по строительству и жилищно-коммунальному хозяйству;</li>
<li>ведомственные нормативно-методические документы.</li>
</ul>
</div>

<div class="article-text">
<strong>База данных характеристик современных строительных материалов "ГРАНД-СтройМатериалы"</strong> включает:
<ul>
<li>справочник по строительным материалам и изделиям;</li>
<li>производственно-технические характеристики более 150 тысяч современных строительных материалов, изделий, конструкций и технологий.</li>
</ul>
</div>
<div class="p4 mt-3">По вопросам приобретения обращайтесь к <span class="link-inline" routerLink="/contacts" fragment="cont_bim">нашим специалистам</span>.</div>
</div>
