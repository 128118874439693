<div class="fade-in-content px-3">
        <h3>
          Генеральный директор
        </h3>
        <p class="p3"> 
            Фадеева Галина Васильевна
        </p>
        <p class="p3"> 
            <span class="post">Приемная:</span>
        </p>
        <p class="p4"> 
          <object class="icon-inline" type="image/svg+xml" data="../images/phone.svg"></object>
          (3812) 24-67-15
          <object class="icon-inline icon-inline-ml" type="image/svg+xml" data="../images/mail.svg"></object>
          mail@srccs.ru
        </p>
        <h3 class="pt-4">
          Исполнительный директор
        </h3>
        <p class="p3"> 
            Бендюков Сергей Геннадьевич
        </p>
        <p class="p4"> 
          <object class="icon-inline" type="image/svg+xml" data="../images/phone.svg"></object>
          (3812) 21-01-78
          <object class="icon-inline icon-inline-ml" type="image/svg+xml" data="../images/mail.svg"></object>
          mail@srccs.ru
        </p>
        <h3 class="pt-4">
          Бухгалтерия
        </h3>
        <p class="p3"> 
            <span class="post">Главный бухгалтер:</span> Ермакова Екатерина Анатольевна
        </p>
        <p class="p4"> 
          <object class="icon-inline" type="image/svg+xml" data="../images/phone.svg"></object>
          (3812) 24-76-23
          <object class="icon-inline icon-inline-ml" type="image/svg+xml" data="../images/mail.svg"></object>
          finance@srccs.ru
        </p>
        <h3 class="pt-4">
          Отдел сметного нормирования и ЖКХ
        </h3>
        <p class="p3"> 
            <span class="post">Начальник отдела:</span> Яровая Наталья Борисовна
        </p>
        <p class="p4"> 
          <object class="icon-inline" type="image/svg+xml" data="../images/phone.svg"></object>
          (3812) 27-88-52
          <object class="icon-inline icon-inline-ml" type="image/svg+xml" data="../images/mail.svg"></object>
          nnb@srccs.ru
        </p>
        <h3 class="pt-4">
          Управление технологического и энергетического оборудования
        </h3>
        <p class="p3"> 
            <span class="post">Начальник управления:</span> Герасименко Максим Николаевич
        </p>
        <p class="p4"> 
          <object class="icon-inline" type="image/svg+xml" data="../images/phone.svg"></object>
          (3812) 21-01-75
          <object class="icon-inline icon-inline-ml" type="image/svg+xml" data="../images/mail.svg"></object>
          oborud@srccs.ru
        </p>
        <p class="p3"> 
            <span class="post">Начальник отдела технологического оборудования:</span> Крутолапов Олег Игоревич
        </p>
        <p class="p4"> 
          <object class="icon-inline" type="image/svg+xml" data="../images/phone.svg"></object>
          (3812) 21-01-75
          <object class="icon-inline icon-inline-ml" type="image/svg+xml" data="../images/mail.svg"></object>
          oborud@srccs.ru
        </p>
        <p class="p3"> 
            <span class="post">Начальник отдела энергетического оборудования:</span> Сигуев Алексей Сергеевич
        </p>
        <p class="p4"> 
          <object class="icon-inline" type="image/svg+xml" data="../images/phone.svg"></object>
          (3812) 21-01-96
          <object class="icon-inline icon-inline-ml" type="image/svg+xml" data="../images/mail.svg"></object>
          energy@srccs.ru
        </p>
        <h3 class="pt-4">
          Отдел ценообразования и смет
        </h3>
        <p class="p3"> 
            <span class="post">Начальник отдела:</span> Русанова Марина Николаевна
        </p>
        <p class="p4"> 
          <object class="icon-inline" type="image/svg+xml" data="../images/phone.svg"></object>
          (3812) 21-01-82
        </p>
        <p class="p3"> 
            <span class="post">Диспетчер по приему и выдаче смет:</span>
        </p>
        <p class="p4"> 
          <object class="icon-inline" type="image/svg+xml" data="../images/phone.svg"></object>
          (3812) 24-68-67
        </p>
        <p class="p4 pt-3"> 
          <object class="icon-inline" type="image/svg+xml" data="../images/mail.svg"></object>
          smeta@srccs.ru
        </p>
        <h3 class="pt-4">
          Отдел строительных материалов
        </h3>
        <p class="p3"> 
            <span class="post">Начальник отдела, зам. генерального директора:</span> Дымченко Андрей Владимирович
        </p>
        <p class="p4"> 
          <object class="icon-inline" type="image/svg+xml" data="../images/phone.svg"></object>
          (3812) 21-01-79
          <object class="icon-inline icon-inline-ml" type="image/svg+xml" data="../images/mail.svg"></object>
          buro@srccs.ru
        </p>
        <h3 class="pt-4">
          Редакционно-издательский отдел
        </h3>
        <p class="p3"> 
            <span class="post">Начальник отдела:</span> Торговых Константин Александрович
        </p>
        <p class="p4"> 
          <object class="icon-inline" type="image/svg+xml" data="../images/phone.svg"></object>
          (3812) 24-17-31, 27-88-51
          <span class="icon-span">.</span>
          <span style="white-space: nowrap;">
            <object class="icon-inline" type="image/svg+xml" data="../images/mail.svg"></object>
            omskgrandsmeta@mail.ru
          </span>
        </p>
</div>