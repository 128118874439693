<div class="fade-in-content">
      <p class="article-header">
      Семинары
      </p>
      <p class="p4 text-center">
Центр ценообразования совместно с представителями ООО МГК "ГРАНД" проводит ежегодные Всероссийские семинары по теме: 
</p>
<p class="p3 text-center color-bl-gr">
<b>Сметно-нормативная база ГЭСН, ФЕР. Новые требования к определению стоимости строительства. ПК "ГРАНД-Смета" новые версии</b>
</p>
<div class="p3 pt-3">
Программа семинара:
<ul class="p4">
<li>Новая сметно-нормативная база ГЭСН, ФЕР в составе программного комплекса "ГРАНД-Смета". Сравнение с предыдущей редакцией сметно-нормативной базы.</li>
<li>Обзор новых нормативно-методических документов по ценообразованию в строительстве.</li>
<li>Презентация возможностей ПК "ГРАНД-Смета".</li>
<li>Применение баз данных "Электронная библиотека сметчика" и "ГРАНД-СтройМатериалы" в составе ПК "ГРАНД-Смета" при составлении и проверке сметной документации.</li>
<li>Ответы на вопросы.</li>
</ul>
</div>
<p class="p3 text-center pt-3">
Лекторами семинара выступают: <span class="color-bl-me">руководители ООО МГК "ГРАНД" и ЗАО "Сибирский ЦЦСПЭ"</span>.
</p>

<p class="p3 text-center">
УЧАСТИЕ В СЕМИНАРЕ – БЕСПЛАТНО!
</p>

<p class="p3 text-center">
ОБЯЗАТЕЛЬНА ПРЕДВАРИТЕЛЬНАЯ РЕГИСТРАЦИЯ! 
</p>


</div>
