<div class="fade-in-content px-3">
        <h3 class="pt-0">
          Адрес:
        </h3>
        <p class="p3"> 
            644043, г. Омск, ул. Октябрьская, 33 (<span class="link-inline" title="Посмотреть на карте" (click)="goToMap()">карта</span>)
        </p>
        <h3 class="pt-2">
          Режим работы:
        </h3>
        <p class="p3"> 
          с 8:30 до 17:30, обед 12:30 до 13:30. Суббота, воскресенье - выходной
        </p>
        <h3 class="pt-2">
          <div id="cont_rec" class="anchor"></div>
          Приемная:
        </h3>
        <p class="p3"> 
          (3812) 24-67-15
        </p>
        <h3 class="pt-2">
          Электронная почта:
        </h3>
        <p class="p3"> 
           mail@srccs.ru
        </p>

        <hr/>

        <h4>
          Бухгалтерия:
        </h4>
        <p class="p4"> 
          <object class="icon-inline" type="image/svg+xml" data="../images/phone.svg"></object>
          (3812) 24-76-23
          <object class="icon-inline icon-inline-ml" type="image/svg+xml" data="../images/mail.svg"></object>
          finance@srccs.ru
        </p>
        <h4>
          Диспетчер по приему и выдаче смет:
        </h4>
        <p class="p4"> 
          <object class="icon-inline" type="image/svg+xml" data="../images/phone.svg"></object>
          (3812) 24-68-67
          <object class="icon-inline icon-inline-ml" type="image/svg+xml" data="../images/mail.svg"></object>
          smeta@srccs.ru
        </p>
        <h4>
          <div id="cont_bim" class="anchor"></div>
          Гранд-Смета, Бюллетень Информационных Материалов:
        </h4>
        <p class="p4"> 
          <object class="icon-inline" type="image/svg+xml" data="../images/phone.svg"></object>
          (3812) 24-17-31, 27-88-51
          <span class="icon-span">.</span>
          <span style="white-space: nowrap;">
            <object class="icon-inline" type="image/svg+xml" data="../images/mail.svg"></object>
            omskgrandsmeta@mail.ru
          </span>
        </p>
        <div #mapsrccs id="mapsrccs" class="mt-3 pt-3" style="border-top:1px solid #E5E5E5;">
        <a class="image-link" title="Посмотреть месторасположение на карте города Омска" target="_blank" 
             href="https://2gis.ru/omsk/firm/282003257766934?queryState=center%2F73.355606%2C54.998598%2Fzoom%2F18">
                  <img src="../images/map_srccs.png" width="100%"/>

        </a>
        </div>
</div>