<div class="fade-in-content">
    <div class="row justify-content-center py-3 px-0">
        <img class="w-75" src="../../images/kollektiv.jpg" />
    </div>
      <p class="article-text">
Сибирский центр ценообразования создан в 1994 году на базе «Главомскпромстроя». Учредителями Центра ценообразования выступили 26 организаций, в т.ч. Администрация Омской области, Омский нефтеперерабатывающий завод, строительные, монтажные и транспортные организации.
      </p>
      <p class="article-text">
C 1994 года генеральным директором ЗАО «Сибирский центр ценообразования в строительстве, промышленности и энергетике» является Фадеева Галина Васильевна. 
      </p>
      <p class="article-text">
Со дня основания Центр ценообразования занимается вопросами ценообразования в различных отраслях: строительство, ЖКХ, горнодобывающая и металлургическая промышленность, железные дороги, энергетика, транспорт и др.
      </p>
      <p class="article-text">
На сегодняшний день в Центре ценообразования трудятся более 40 специалистов – инженеры-строители, механики, энергетики, экономические и юридические службы. Эффективная деятельность предприятия помимо квалифицированных трудовых ресурсов обеспечивается наличием хорошей материально-технической базы, собственных зданий, учебных классов, типографии. Ежеквартально формируется и издается Бюллетень информационных материалов для строителей. Центр ценообразования осуществляет свою деятельность в 16 регионах Российской Федерации.
      </p>
</div>