import { Directive, Input, Output, EventEmitter, OnChanges, OnDestroy, ElementRef, HostListener, DoCheck } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';

import { Subject, Observable, SubscriptionLike, timer } from "rxjs";

@Directive({ 
  selector: '[inputPhoneMasking]',
    host: {
    '(input)': 'onInputChange($event, false)',
    '(focusin)': 'onFocusIn($event)',
    //'(keydown.backspace)': 'onInputChange($event, true)',
    //'(keydown.delete)': 'onInputChange($event, false)',
  }

})
export class PhoneMaskingDirective implements DoCheck {

  private el: HTMLInputElement;
  private lastValue: string = "";
  private oldVal: string = "";
  private savePosForBackspace: number = -1;

  constructor(public model: NgControl, private ref: ElementRef) {
    this.el = this.ref.nativeElement;
  }

  ngDoCheck() {
    if (this.el.value == '') { 
      this.el.value = '(___) ___-__-__';
      this.el.selectionStart = this.el.selectionEnd = 1; 
    }
  }     

  onFocusIn(event) {
    this.oldVal = event.target.value.replace(/\D/g, '');
  }

  onInputChange(event, backspace) {
    var newVal = event.target.value.replace(/\D/g, '');
    var pos = this.el.selectionEnd;    

    if (event.inputType == 'deleteContentBackward' && newVal == this.oldVal) {
      while (pos>1 && event.target.value.charAt (pos-1).replace(/\D/g, '') == '') {
        pos=pos-1;
      }
      newVal = event.target.value.slice(0,pos-1) + event.target.value.slice(pos,16);
      pos=pos-1;
      newVal = newVal.replace(/\D/g, '');
    }
    if (event.inputType == 'deleteContentForward' && newVal == this.oldVal) {
      while (pos<15 && event.target.value.charAt (pos).replace(/\D/g, '') == '') {
        pos=pos+1;
      }
      newVal = event.target.value.slice(0,pos) + event.target.value.slice(pos+1,16);
      pos=this.el.selectionEnd;    
      newVal = newVal.replace(/\D/g, '');
    }

    if (newVal.length == 0) {
      newVal = '(___) ___-__-__';
    } else if (newVal.length == 1) {
      newVal = newVal.replace(/^(\d{0,3})/, '($1__) ___-__-__');
    } else if (newVal.length == 2) {
      newVal = newVal.replace(/^(\d{0,3})/, '($1_) ___-__-__');
    } else if (newVal.length == 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '($1) ___-__-__');
    } else if (newVal.length == 4) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2__-__-__');
    } else if (newVal.length == 5) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2_-__-__');
    } else if (newVal.length == 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2-__-__');
    } else if (newVal.length == 7) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,2})/, '($1) $2-$3_-__');
    } else if (newVal.length == 8) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,2})/, '($1) $2-$3-__');
    } else if (newVal.length == 9) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,2})(.*)/, '($1) $2-$3-$4_');
    } else {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,2})(.*)/, '($1) $2-$3-$4');
    }
    if(newVal.length > 15) {
       newVal = newVal.slice(0, 15); 
       this.lastValue = "";
    }
    this.oldVal=newVal.replace(/\D/g, '');
    if (pos<=0) pos=1;
    if (pos==4) pos=6;
    if (pos==9 || pos==12) pos=pos+1;

    if (!this.lastValue || (this.lastValue && this.lastValue !== newVal ) || (this.lastValue && this.lastValue !== this.model.control.value ))
    {
      this.lastValue = newVal;
      this.model.control.setValue(newVal);
    }
    this.el.selectionStart = this.el.selectionEnd = pos;
  }
}