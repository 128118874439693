import { Component, HostListener, AfterViewInit, ViewChild, ViewContainerRef} from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AppComponent } from './app.component';

@Component({
    selector: "sections",
    templateUrl: "sections.component.html"
})


export class SectionsComponent {
  public title: String;
  public links;
  public currroute: String;

  constructor (private activeRoute: ActivatedRoute, private appl: AppComponent) {
    this.currroute = activeRoute.snapshot.url[0].path;

    switch (this.currroute) {
      case "services": {
        this.title="Услуги";
        this.links = [ { target: "smets", title: "сметы",
                           children: [ { target: "progs", title: "программмные продукты" }, 
                                       { target: "indexes", title: "индексы и ценники" } ]
                       },
                       { target: "resources", title: "ресурсы",
                           children: [ { target: "monitoring", title: "мониторинг" }] 
//                                       { target: "transport-schemes", title: "транспортные схемы" } 
                       },
                       { target: "norms", title: "нормативы", 
                           children: [ { target: "building", title: "строительство" }, 
                                       { target: "repairs", title: "нормативная база" },
                                       { target: "toir", title: "технологические карты" },
                                       { target: "asutp", title: "АСУ ТП" } ]
                       },
                       { target: "training", title: "обучение", 
                            children: [ { target: "course", title: "курсы" }, 
                                        { target: "seminar", title: "семинары" } ]
                       }
                     ];
        break;
      }
      case "about": {
        this.title="О компании";
        this.links = [ { target: "history", title: "история"},
                       { target: "heads", title: "руководство"},
                       { target: "awards", title: "достижения"},
                       { target: "portfolio", title: "партнеры"},
                       { target: "gallery", title: "фотогалерея"},
                           //children: [ { target: "norilsk", title: "партнер 1" }, 
                           //            { target: "alrosa1", title: "партнер 2" },
                           //            { target: "alrosa2", title: "партнер 3" },
                           //            { target: "alrosa3", title: "партнер 4" }] }
                ];
        break;
      }
      case "contacts": {
        this.title="Контакты";
        this.links = [ { target: ".", title: "основные"},
                       { target: "detailed", title: "подробно" }
                     ];
        break;
      }
    }


  }

  @ViewChild('mobileOutlet', { read: ViewContainerRef }) _mobileOutlet; 
  @ViewChild('desktopOutlet', { read: ViewContainerRef }) _desktopOutlet; 
  @ViewChild('routlet') _routlet; 

  ngAfterViewInit() {
    this.appl.isWidthTablet().subscribe(value => this.setOutlet(value));
  }

  setOutlet(value: boolean) {
    if (value) {
      if (this._mobileOutlet.length == 0) {
        this._desktopOutlet.clear();
        this._mobileOutlet.createEmbeddedView(this._routlet);
      }
    } else {
      if (this._desktopOutlet.length == 0) {
        this._mobileOutlet.clear();
        this._desktopOutlet.createEmbeddedView(this._routlet);
      }
    }
  }

  isActive (elid: string) : boolean {
    let result = false;
    if (this.appl.isBrowser && document.getElementById(elid) != null)
      result = document.getElementById(elid).className.indexOf('active') !=-1;
    return result;
  }

  lvl2Margin (link: any) : number {
    let elid = link.target;
    let result = 0;
    if (this.appl.isBrowser && document.getElementById(elid) != null && document.getElementById(elid).className.indexOf('active') !=-1)
      result = 0;
    else
      result = -link.children.length*1.7;   
    return result;
  }

}
