<div class="fade-in-content">
      <p class="article-header">
      Индексы и Ценники (Омский регион)
      </p>
      <p class="article-text">
Предлагаем приобрести разработанные Центром ценообразования печатные и электронные (в формате ПК "ГРАНД-Смета") сборники текущих сметных цен на строительные материалы, тарифные ставки рабочих и служащих, стоимость 1 маш.ч механизмов, используемых при строительстве, тарифы на погрузо-разгрузочные работы и перевозку грузов. А также индексы пересчета ТСНБ-2001 Омской области в текущий уровень цен.
      </p>

      <p class="p3">
      Цены на продукцию ЗАО "СЦЦСПЭ" за 2023 год:
      </p>
      <div class="table-price table-price-indexes">
      <table>
        <tr>
          <th rowspan=2>№</th>
          <th rowspan=2>Наименование</th>
          <th colspan=2>Стоимость, &#8381;</th>
        </tr>
        <tr>
          <th>За квартал</th>
          <th>За год</th>
        </tr>
        <tr>
          <td *ngIf="price(2).length==0" colspan=4>Выполняется загрузка данных...</td>
        </tr>
        <tr *ngFor="let item of price(2); let i = index;">
          <td>{{ i+1 }}</td>
          <td [innerHTML]="item.name | safeHtml">
          </td>
          <td [innerHTML]="item.costQ | numberSpaceSeparator | safeHtml"></td>
          <td [innerHTML]="item.costY | numberSpaceSeparator | safeHtml"></td>
        </tr>
      </table>
      </div>

     <div class="p4 mt-3">По вопросам приобретения обращайтесь к <span class="link-inline" routerLink="/contacts" fragment="cont_bim">нашим специалистам</span>.</div>
</div>
