import { Component, AfterViewChecked } from "@angular/core";
import { PriceIndexesRepository } from "../../model/PriceIndexes.repository";
import { PriceIndexesItem } from "../../model/PriceIndexesItem.model";
import { AppComponent } from '../../app.component';

declare var $:any;

@Component({
    selector: "smets/indexes",
    templateUrl: "indexes.component.html"
})

export class ServicesSmetsIndexesComponent {
    public loadedPrice: PriceIndexesItem[][] = [ [],[],[] ];
    private ttenabled: boolean[] = [false, false, false];

    constructor (private appl: AppComponent, private repository: PriceIndexesRepository) {
    }

    ngAfterViewChecked() {
      for (let i = 0; i < 3; i++) {
        if (this.appl.isBrowser && !this.ttenabled[i] && this.loadedPrice[i].length!=0) {
          this.ttenabled[i] = true;
          $('.tt').tooltip();
        }
      }
    }

   price(priceId: number): PriceIndexesItem[] {
     if (this.loadedPrice[priceId].length==0) this.loadedPrice[priceId] = this.repository.getPrice(priceId);
     return this.loadedPrice[priceId];
   }
}
