import { Injectable } from "@angular/core";
import { NamedUrl } from "./NamedUrl.model";
import { srccsWebServiceDataSource } from "./srccsWebService.datasource";

@Injectable({providedIn: 'root'})
export class HomeSliderImagesRepository {
    private images: NamedUrl[] = [];

    constructor(private api: srccsWebServiceDataSource) {
          api.getData("homesliderimages").subscribe(data => {
              this.images = data;
              //console.log ("Subscribe event: " + this.images);
          });
    }

    getImages(): NamedUrl[] {
        //console.log ("Return from repository: " + this.images.length);
        return this.images;
    }
}
