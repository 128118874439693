      <h1 style="position: absolute; top:-1000px;">Главная страница</h1>
      <div class="main-content fade-in-content new-year-margin">
        <div *ngIf="sliderimages.length > 0" class="visible-for-wide-block">
          <div id="carousel" class="carousel slide carousel-fade" data-ride="carousel">
            <ol class="carousel-indicators">
              <li data-target="#carousel" *ngFor="let sliderimage of sliderimages; let i = index" [class.active]="i==0" [attr.data-slide-to]="i"></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item" *ngFor="let sliderimage of sliderimages; let i = index" [class.active]="i==0">
                <img class="img-fluid w-100" src="../images/slider/{{sliderimage.name}}" [routerLink]="sliderimage.url">
              </div>
            </div>
            <a class="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="false"></span>
              <span class="sr-only">Предыдущий</span>
            </a>
            <a class="carousel-control-next" href="#carousel" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Следующий</span>
            </a>
          </div>
        </div>
        <div class="caontainer-fluid cards-homepage" >
          <div class="row m-0 px-1 pb-5">
            <div id="card1" class="col-md-6 col-xxl-3 card-padding">
              <div class="card shadow h-100" routerLink="/services/smets">
                <div class="card-header px-2 pt-2 pb-1">
                  <div class="d-flex flex-nowrap p-0 m-0 align-items-center">
                    <a class="svg-cursor">
                      <object height=32 width=32 class="" type="image/svg+xml" data="../images/budget.svg"></object>
                    </a>
                     <h2>
                      Сметы
                     </h2>
                   </div> 
                </div>
                <div class="card-body">
                  <ul>
                    <li>
                      проверка и составление сметной документации
                    </li>
                    <li>
                      подготовка заключений негосударственной экспертизы сметной стоимости строительно-монтажных работ
                    </li>
                    <li>
                      подготовка заключений по предарбитражным спорам в вопросах за выполненные объемы работ
                    </li>
                    <li>
                      проведение судебной строительно-технической экспертизы (специальность "16.1. Исследования строительных объектов и территории, функционально связанной с ними, с целью определения их стоимости")
                    </li>
                    <li>
                      продажа и сопровождение ПК "Гранд-Смета"
                    </li>
                  </ul>
                </div>
                <div class="card-footer">
                  <button class="btn btn-primary">Подробнее</button>
                </div>
              </div>
            </div>
            <div id="card2" class="col-md-6 col-xxl-3 card-padding">
              <div class="card shadow h-100" routerLink="/services/resources">
                <div class="card-header px-2 pt-2 pb-1">
                  <div class="d-flex flex-nowrap p-0 m-0 align-items-center">
                    <a class="svg-cursor">
                      <object height=32 width=32 class="" type="image/svg+xml" data="../images/brick.svg"></object>
                     </a> 
                     <h2>
                      Ресурсы
                     </h2>
                   </div> 
                </div>
                <div class="card-body" >
                  <ul>
                    <li>
                      формирование сборников текущих сметных цен на строительные материалы
                    </li>
                    <li>
                      расчет тарифной ставки рабочих и служащих
                    </li>
                    <li>
                      расчет стоимости 1 маш.ч механизмов
                    </li>
                    <li>
                      расчет тарифов на погрузо-разгрузочные работы и перевозку грузов
                    </li>
                    <li>
                      пересчет индексов сметной стоимости строительства
                    </li>
                    <li>
                      мониторинг стоимости материальных ресурсов
                    </li>
                    <li>
                      формирование транспортных схем по доставке материалов
                    </li>
                  </ul>
                </div>
                <div class="card-footer">
                  <button class="btn btn-primary">Подробнее</button>
                </div>
              </div>
            </div>
            <div id="card3" class="col-md-6 col-xxl-3 card-padding">
              <div class="card shadow h-100" routerLink="/services/norms">
                <div class="card-header px-2 pt-2 pb-1">
                  <div class="d-flex flex-nowrap p-0 m-0 align-items-center" >
                    <a class="svg-cursor">
                      <object height=32 width=32 class="" type="image/svg+xml" data="../images/caliper.svg"></object>
                    </a>
                     <h2>
                 Нормативы
                     </h2>
                   </div> 
                </div>
                <div class="card-body">
                  <ul>
                    <li>
                      формирование сметно-нормативных баз по ремонту технологического и энергетического оборудования ресурсным методом
                    </li>
                    <li>
                      технологические карты на все типы оборудования
                    </li>
                    <li>
                      разработка ресурсных норм и корпоративных баз укрупненных комплексных расценок
                    </li>
                    <li>
                      определение величины накладных расходов по предприятиям различных отраслей
                    </li>
                  </ul>
                </div>
                <div class="card-footer">
                  <button class="btn btn-primary">Подробнее</button>
                </div>
              </div>
            </div>
            <div id="card4" class="col-md-6 col-xxl-3 card-padding">
              <div class="card shadow h-100" routerLink="/services/training">
                <div class="card-header px-2 pt-2 pb-1">
                  <div class="d-flex flex-nowrap p-0 m-0 align-items-center">
                    <a class="svg-cursor">
                      <object height=32 width=32 class="cardsimage" type="image/svg+xml" data="../images/graduate-cap.svg"></object>
                    </a>
                     <h2>
                  Обучение
                     </h2>
                   </div> 
                </div>
                <div class="card-body">
                  <ul>
                    <li>
                      проведение курсов повышения квалификации специалистов по вопросам сметного нормирования и ценообразования
                    </li>
                    <li>
                      организация семинаров, направленных на повышение квалификации инженеров-сметчиков и проводимых совместно с представителями разработчиков ПК "ГРАНД-Смета"
                    </li>
                  </ul>
                </div>
                <div class="card-footer">
                  <button class="btn btn-primary">Подробнее</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
